import React from "react";

export default function CategoryColumns() {
  return (
    <div className="row">
      <div className="col-11 col-lg-1">Category</div>
      <div className="col-11 col-lg-1">类别</div>
      <div className="col-11 col-lg-1">shelfPos</div>
      <div className="col-11 col-lg-2">image</div>
      <div className="col-11 col-lg-5">info</div>
      <div className="col-11 col-lg-1">active</div>

      <div className="col-11 col-lg-1">edit | delete</div>
    </div>
  );
}
