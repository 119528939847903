import React, { useContext, useState } from "react";

import AuthContext from "../../context/authContext";
import OrderCheckout from "../Order/OrderCheckout";
import AuthModal from "../User/AuthModal";

export default function CartCheckout() {
  const { token } = useContext(AuthContext);

  const [checkout, setCheckout] = useState(false);

  return (
    <>
      {!checkout ? (
        <div className="d-flex justify-content-center">
          {token ? (
            <button
              className="btn btn-success"
              onClick={() => setCheckout(true)}
            >
              <i className="fas fa-cash-register">Checkout</i>
            </button>
          ) : (
            <div className="d-flex gap-2">
              <div className="d-flex align-items-center flex-column">
                <div className="btn btn-success p-0">
                  <AuthModal />
                </div>
                <div>
                  <small className="text-muted">Login to buy</small>
                </div>
              </div>
              or
              <div>
                <button
                  className="btn btn-outline-success"
                  onClick={() => setCheckout(true)}
                >
                  <i className="fas fa-cash-register">Checkout</i>
                </button>
                <br />
                <div className="text-center">
                  <small className="text-muted">as a Guest</small>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <OrderCheckout />
      )}
    </>
  );
}
