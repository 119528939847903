import React, {
  useState,
  useEffect,
  useCallback,
  PropsWithChildren,
} from "react";

import authContext from "./authContext";

var logoutTimer: ReturnType<typeof setTimeout>;
const LOGIN_TOKEN_DURATION = 3600 * 1000; // 1 hour

const AuthProvider = (props: PropsWithChildren) => {
  const [token, setToken] = useState<string | null>(null);
  const [tokenExpireDate, setTokenExpireDate] = useState<Date | null>(null);
  const [user_id, setUserId] = useState<string | null>(null);
  const [user_fname, setuser_fname] = useState("");
  const [admin, setAdmin] = useState(false);

  const [langEn, setLangEn] = useState(true);

  const setLang = () => {
    localStorage.setItem(
      process.env.REACT_APP_LS_LANGEN
        ? process.env.REACT_APP_LS_LANGEN
        : "lang",
      String(!langEn)
    );
    setLangEn(!langEn);
  };

  useEffect(() => {
    const _lang = localStorage.getItem(
      process.env.REACT_APP_LS_LANGEN ? process.env.REACT_APP_LS_LANGEN : "lang"
    );
    _lang && setLangEn(_lang === "true");
  }, []);

  const login = useCallback(
    (
      _token: string,
      _user_id: string,
      _fname: string,
      _role: string,
      _expDate?: Date
    ): void => {
      setToken(_token);
      setUserId(_user_id);
      setuser_fname(_fname);
      setAdmin(_role === "admin" ? true : false);
      const _tokenExpDate =
        _expDate || new Date(new Date().getTime() + LOGIN_TOKEN_DURATION);
      setTokenExpireDate(_tokenExpDate);
      // if login first time (_expDate NOT provided), _tokenExpDate is set to new Date()+1hr
      // if login from useEffect reload (_expDate provided), tokenExpDate is set to _expDate.

      localStorage.setItem(
        process.env.REACT_APP_LS_TOKEN ? process.env.REACT_APP_LS_TOKEN : "",
        JSON.stringify({
          token: _token,
          user_id: _user_id,
          user_fname: _fname,
          user_role: _role,
          expiration: _tokenExpDate.toISOString(),
        })
      );
    },
    []
  );

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpireDate(null);
    setUserId(null);
    localStorage.removeItem(
      process.env.REACT_APP_LS_TOKEN ? process.env.REACT_APP_LS_TOKEN : ""
    );
  }, []);

  useEffect(() => {
    if (token && tokenExpireDate) {
      const remainingTime = tokenExpireDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      // logout();
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpireDate]);

  useEffect(() => {
    const lsToken = localStorage.getItem(
      process.env.REACT_APP_LS_TOKEN
        ? process.env.REACT_APP_LS_TOKEN
        : "token_data"
    );
    if (!lsToken) return;

    const storedData = JSON.parse(lsToken);
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.token,
        storedData.user_id,
        storedData.user_fname,
        storedData.user_role,
        new Date(storedData.expiration)
      );
    } else if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) < new Date()
    ) {
      logout();
    }
  }, [login, logout]);

  return (
    <authContext.Provider
      value={{
        langEn,
        setLang,
        token,
        user_id,
        user_fname,
        admin,
        login,
        logout,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
};

export default AuthProvider;
