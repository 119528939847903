import React, { useContext } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FaShoppingCart } from "react-icons/fa";

import authContext from "../context/authContext";
import productContext from "../context/productContext";
import "./ShopNavbar.css";
import AuthModal from "./User/AuthModal";
import CartContext from "../context/CartContext";

function ShopNavbar() {
  const { token, admin, user_fname, logout, langEn, setLang } =
    useContext(authContext);
  const { catList } = useContext(productContext);
  const { cart } = useContext(CartContext);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      // bg='primary'
      variant="dark"
      sticky="top"
      className="px-2"
    >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />

      <Navbar.Brand href="/">
        <img
          src={"/logo_BeFAQT.ico"}
          alt="BeFAQT logo"
          style={{ width: "36px" }}
        />
        <span> BeFAQT</span>
      </Navbar.Brand>

      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav>
          <NavDropdown title={<span>{langEn ? "👨‍👩‍👧‍👦About" : "🗂关于"}</span>}>
            <NavDropdown.Item href="/about/project">
              <span role="img" aria-label="order">
                🛠{langEn ? "Project" : "项目"}
              </span>
            </NavDropdown.Item>
            <NavDropdown.Item href="/about/tech">
              <span role="img" aria-label="address">
                🔗{langEn ? "Technologies" : "科技"}
              </span>
            </NavDropdown.Item>
            <NavDropdown.Item href="/about/us">
              <span role="img" aria-label="address">
                👨🏼‍🤝‍👨🏻{langEn ? "Team" : "团队"}
              </span>
            </NavDropdown.Item>
          </NavDropdown>

          {catList.map((item) => {
            return (
              <Nav.Link key={item.name} href={`/productsbycat/${item.name}`}>
                {langEn ? item.name : item.nameCN}
              </Nav.Link>
            );
          })}
        </Nav>
        <Nav.Link href={"/products/search"}>
          <span role="img" aria-label="search">
            🔍 {langEn ? "Search" : "检索"}
          </span>
        </Nav.Link>
        <Nav.Link href={"/contact"}>
          <span role="img" aria-label="search">
            ✍️ {langEn ? "Contact" : "检索"}
          </span>
        </Nav.Link>
      </Navbar.Collapse>

      {token ? (
        <>
          {admin ? (
            <NavDropdown
              className="ml-auto"
              title={
                <span className="text-danger">
                  <i className="fas fa-user-cog"></i>
                  <span className="d-none d-md-inline">{user_fname}</span>
                </span>
              }
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/product/manage">
                {langEn ? "Manage products" : "商品管理"}
              </NavDropdown.Item>
              <NavDropdown.Item href="/user/manage">
                {langEn ? "Manage users" : "用户管理"}
              </NavDropdown.Item>
              <NavDropdown.Item href="/order/manage">
                {langEn ? "Manage orders" : "订单管理"}
              </NavDropdown.Item>
              <NavDropdown.Item href="/category/manage">
                {langEn ? "Manage categories" : "类别管理"}
              </NavDropdown.Item>
              <NavDropdown.Item
                href="/"
                onClick={logout}
                className="text-warning"
              >
                {langEn ? "Logout " : "退出 "}
                <i className="fas fa-sign-out-alt"></i>
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <NavDropdown
              className="ml-auto"
              title={
                <span className="text-warning">
                  <i className="fas fa-user"></i>
                  <span className="d-none d-md-inline">{user_fname}</span>
                </span>
              }
            >
              <NavDropdown.Item href="/order/myorders">
                <span role="img" aria-label="order">
                  🧾 {langEn ? "MyOrders" : "我的订单"}
                </span>
              </NavDropdown.Item>
              <NavDropdown.Item href="/user/update">
                <span role="img" aria-label="address">
                  🏠 {langEn ? "MyProfile" : "我的地址"}
                </span>
              </NavDropdown.Item>
              <NavDropdown.Item
                className="text-warning"
                href="/"
                onClick={logout}
              >
                {langEn ? "Logout " : "退出 "}
                <i className="fas fa-sign-out-alt"></i>
              </NavDropdown.Item>
            </NavDropdown>
          )}
        </>
      ) : (
        <div className="ml-auto">
          <AuthModal />
        </div>
      )}

      <Nav.Link href="/cart">
        <span style={{ fontSize: "1.6rem", color: "#00ff00" }}>
          <FaShoppingCart />
        </span>
        <span className="badge badge-warning" id="lblCartCount">
          {cart ? cart.reduce((count, item) => count + item.quantity, 0) : 0}
        </span>
      </Nav.Link>

      <button className="btn" onClick={setLang}>
        {langEn ? (
          <img
            src={"/img/australia-flag-round-icon-32.png"}
            alt="cn"
            title="切换到中文"
          />
        ) : (
          <img
            src={"/img/china-flag-round-icon-32.png"}
            alt="au"
            title="English"
          />
        )}
      </button>
    </Navbar>
  );
}

export default ShopNavbar;
