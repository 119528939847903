import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import authContext from "../../context/authContext";
import UserInterface, { UserInfoInterface } from "./UserInterface";

export default function UserUpdate() {
  const { langEn, token } = useContext(authContext);
  const navigate = useNavigate();
  const [userSelf, setUserSelf] = useState({} as UserInterface);
  const [updateUser, setUpdateUser] = useState({} as UserInfoInterface);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/getSelf",
          {
            headers: { "x-access-token": token },
          }
        );
        const user: UserInterface = resDoc.data;
        setUserSelf(user);
        setUpdateUser({
          fname: user.fname,
          lname: user.lname,
          phone: user.phone,
          address: user.address,
        });
      } catch (error) {
        console.error(error);
      }
    };
    token && fetchUser();
  }, [token]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUpdateUser((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleStreetInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUpdateUser((prevState) => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      };
    });
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/user/updateSelf",
        updateUser,
        {
          headers: { "x-access-token": token },
        }
      );
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="container">
      <h1>{langEn ? "Personal Information" : "个人信息"}</h1>

      <div className="card" style={{ width: "25rem" }}>
        <div className="card-header">
          <div className="row">
            <div className="col-4">Name:</div>
            <div className="col-8">
              {userSelf.fname} {userSelf.lname}
            </div>
            <div className="col-4">Email: </div>
            <div className="col-8">{userSelf.email}</div>
            <div className="col-4">Phone: </div>
            <div className="col-8">
              <input
                required
                type="text"
                name="phone"
                value={updateUser.phone || ""}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="form-group col-12">
                <label>Street Address: </label>
                <input
                  className="form-control"
                  type="text"
                  name="line1"
                  value={(updateUser.address && updateUser.address.line1) || ""}
                  onChange={handleStreetInput}
                />
              </div>
              <div className="form-group col-6">
                <label>Suburb: </label>
                <input
                  className="form-control"
                  type="text"
                  name="suburb"
                  value={
                    (updateUser.address && updateUser.address.suburb) || ""
                  }
                  onChange={handleStreetInput}
                />
              </div>
              <div className="form-group col-3">
                <label>State: </label>
                <input
                  className="form-control"
                  type="text"
                  name="state"
                  value={(updateUser.address && updateUser.address.state) || ""}
                  onChange={handleStreetInput}
                />
              </div>
              <div className="form-group col-3 pb-2">
                <label>Post code:</label>
                <input
                  className="form-control"
                  type="text"
                  name="postcode"
                  value={
                    (updateUser.address && updateUser.address.postcode) || ""
                  }
                  onChange={handleStreetInput}
                />
              </div>
            </div>
            <div className="card-footer text-end pt-2">
              <button className="btn btn-primary" type="submit">
                <span role="img" aria-label="shop">
                  💾 Save Changes
                </span>
              </button>{" "}
              <button
                className="btn btn-outline-primary"
                onClick={() => navigate("/")}
              >
                <span role="img" aria-label="shop">
                  🏠 Back to Shop
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
