import { createContext } from "react";
import CategoryInterface from "../components/Category/CategoryInterface";
import ProductInterface from "../components/Product/ProductInterface";

type ProductCtx = {
  catList: CategoryInterface[];
  products: ProductInterface[];
  onShelfProducts: ProductInterface[];
  categories: CategoryInterface[];
  isLoading: boolean;
};

const ProductContext = createContext({} as ProductCtx);

export default ProductContext;
