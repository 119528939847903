import React from "react";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  PolylineF,
} from "@react-google-maps/api";
import Spinner from "react-bootstrap/Spinner";
import MarkerInterface, { LocInterface } from "./MarkerInterface";

type props = {
  center: LocInterface;
  zoom: number;
  markers: MarkerInterface[];
  linked: boolean;
};

export default function MapMarkersLinked({
  center,
  zoom,
  markers,
  linked,
}: props) {
  // multi marker map with first marker as a special icon
  // markers=[{location, title, label}], location={lat, lng}

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    // ...otherOptions
  });

  const polylinePath = markers.map((marker) => marker.location);

  const polylineOptions = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    paths: polylinePath,
    zIndex: 1,
  };

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "420px",
          // height: '100vh',
        }}
        zoom={zoom}
        center={center}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
        }}
      >
        {markers.map((item, index) => {
          return (
            index > 0 && (
              <MarkerF
                key={index}
                position={item.location}
                title={item.title}
                label={item.label}
              />
            )
          );
        })}

        <MarkerF
          position={markers[0].location}
          title="catch provenance"
          icon={{
            url: "/img/marker_fishing.png",
            // zIndex: 1,
            size: { width: 50, height: 50 } as any,
            // origin: { width: 0, height: 0 },
            anchor: { x: 25, y: 25 } as any,
            scaledSize: { width: 50, height: 50 } as any,
          }}
        />

        {linked && <PolylineF path={polylinePath} options={polylineOptions} />}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? (
    renderMap()
  ) : (
    <Spinner animation="border" variant="primary" />
  );
}
