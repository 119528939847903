import React, { useContext } from "react";
import axios from "axios";
import { FaEdit } from "react-icons/fa";

import authContext from "../../context/authContext";
import OrdersByUserModal from "../Order/OrdersByUserModal";
import UserInterface from "../User/UserInterface";

type UserProps = {
  index: number;
  user: UserInterface;
  setEdit: (user_id: string) => void;
};

export default function UserItem({ index, user, setEdit }: UserProps) {
  const { token } = useContext(authContext);

  async function deleteUser() {
    try {
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + "/user/" + user._id,
        {
          headers: { "x-access-token": token },
        }
      );
    } catch (error) {
      console.error(error);
    }

    window.location.reload();
  }

  return (
    <>
      <div className={index % 2 ? "row mt-1" : "row mt-1 bg-white"}>
        <div className="col-2 text-left">{user.fname + " " + user.lname}</div>
        <div className="col-2">{user.email}</div>
        <div className="col-1">{user.role}</div>
        <div className="col-2">{user.phone}</div>
        <div className="col-11 col-lg-3">
          {user.address && user.address.line1}
          <br></br>
          {user.address && user.address.suburb},{" "}
          {user.address && user.address.state}{" "}
          {user.address && user.address.postcode}
        </div>
        <div className="col-1">{user.status}</div>

        <div className="col-1">
          <div className="btn-group" role="group" aria-label="Basic example">
            {user && <OrdersByUserModal user={user} />} |
            <button
              className="btn btn-link"
              onClick={() => setEdit(user._id ? user._id : "")}
            >
              <FaEdit />
            </button>
            {user.status === "disabled" && (
              <button className="btn btn-link" onClick={() => deleteUser()}>
                <i className="fas fa-trash-alt" />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
