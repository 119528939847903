import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  if (
    localStorage.getItem(
      process.env.REACT_APP_LS_TOKEN ? process.env.REACT_APP_LS_TOKEN : "token"
    )
  ) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default PrivateRoute;
