import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaCartPlus } from "react-icons/fa";

import authContext from "../../context/authContext";
import "./Product.css";
import ProductInterface from "./ProductInterface";
import CartContext from "../../context/CartContext";

type ProductItemProps = {
  key?: string;
  product: ProductInterface;
};

function ProductItem({ product }: ProductItemProps) {
  const { cart, addToCart, removeFromCart } = useContext(CartContext);
  const { langEn } = useContext(authContext);
  const [qtyInCart, setQtyInCart] = useState(0);

  const { _id, name, images, price, stock, unit, shelfPos, info } = product;

  useEffect(() => {
    const _itemInCart = cart && cart.find((item) => item.product_id === _id);
    if (_itemInCart) {
      setQtyInCart(_itemInCart.quantity);
    } else {
      setQtyInCart(0);
    }
  }, [_id, cart, qtyInCart]);

  return (
    <div className="card h-100 m-1">
      <span className="card-shelf-text text-muted">{shelfPos}</span>
      <div className="img-container p-3">
        {info!.split(":")[0] === "SFMBox" ? (
          // Show SFMBox, where boxID is embedded in product.info
          // SFMBox product has info: 'SFMBox:0345579444'
          <Link to={`/SFMBoxes/byId/${info!.split(":")[1]}`}>
            <img
              src={process.env.REACT_APP_ASSET_URL + "/" + images}
              alt={name}
              className="card-img-top"
            />
          </Link>
        ) : (
          // not SFMBox: show normal product
          <Link to={`/productbyid/${_id}`}>
            {" "}
            <img
              src={process.env.REACT_APP_ASSET_URL + "/" + images![0]}
              alt={name}
              className="card-img-top"
            />
          </Link>
        )}
      </div>
      <div className="card-footer">
        <p className="text-left mb-0">{name}</p>${price}/{unit}
        {qtyInCart > 0 && (
          <>
            <span className="card-btn-qty">{qtyInCart}</span>
            <button
              className="btn shadow-none card-btn-minus"
              onClick={() => removeFromCart(_id!)}
            >
              {/* <strong>-</strong> */}
              <i className="fas fa-minus"></i>
            </button>
          </>
        )}
        {stock! > 0 ? (
          <button
            className="btn card-btn-add shadow-none"
            title={langEn ? "buy" : "买"}
            onClick={() => _id && addToCart(_id)}
          >
            {/* <strong>+</strong> */}
            {/* <i className='fas fa-plus'></i> */}
            <FaCartPlus />
          </button>
        ) : langEn ? (
          <button className="card-btn-out" disabled>
            Out of Stock
          </button>
        ) : (
          <button className="card-btn-out" disabled>
            已售罄
          </button>
        )}
      </div>
    </div>
  );
}

export default ProductItem;
