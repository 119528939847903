import React, { useContext } from "react";
import Axios from "axios";

import authContext from "../../context/authContext";
import OrderInterface from "./OrderInterface";

type props = { orderItem: OrderInterface };

export default function OrderItem({ orderItem }: props) {
  const { admin, token } = useContext(authContext);

  async function deliverOrder(orderId: string) {
    console.log(orderId, token);

    try {
      await Axios.get(
        process.env.REACT_APP_BACKEND_URL + "/order/deliver/" + orderId,
        {
          headers: { "x-access-token": token },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload();
  }

  async function deleteOrder(orderId: string) {
    try {
      await Axios.delete(
        process.env.REACT_APP_BACKEND_URL + "/order/" + orderId,
        {
          headers: { "x-access-token": token },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload();
  }

  return (
    <div className="card border-success">
      {admin && (
        <div className="card-header">
          {orderItem.deliveredDate ? (
            <div className="row">
              <div className="col-6">Delete:</div>
              <div className="col-6 text-end">
                <button
                  className="btn btn-link"
                  onClick={() => deleteOrder(orderItem._id!)}
                >
                  <i className="fas fa-trash-alt" />
                </button>
              </div>
            </div>
          ) : (
            <div className="col-4">
              <button
                className="btn btn-outline-primary btn-sm"
                onClick={() => deliverOrder(orderItem._id!)}
              >
                <i className="fas fa-truck"> Deliver</i>
              </button>
            </div>
          )}
        </div>
      )}
      {orderItem && orderItem.buyer && (
        <div className="card-header">
          <h5>Buyer:</h5>
          <div className="row">
            <div className="col-3">Name:</div>
            <div className="col-9">
              {orderItem.buyer.fname + " " + orderItem.buyer.lname}
            </div>
            <div className="col-3">email:</div>
            <div className="col-9">{orderItem.buyer.email}</div>
            <div className="col-3">phone:</div>
            <div className="col-9">{orderItem.buyer.phone}</div>
            <div className="col-3">Address:</div>
            {orderItem.buyer.address && (
              <div className="col-9">
                {orderItem.buyer.address.line1}
                <br />
                {orderItem.buyer.address.suburb} {orderItem.buyer.address.state}{" "}
                {orderItem.buyer.address.postcode}
              </div>
            )}
          </div>
        </div>
      )}
      {orderItem && orderItem.delivery && (
        <div className="card-header">
          <h5>Deliver to:</h5>
          <div className="row">
            <div className="col-3">Name:</div>
            <div className="col-9">
              {orderItem.delivery.fname + " " + orderItem.delivery.lname}
            </div>
            <div className="col-3">email:</div>
            <div className="col-9">{orderItem.delivery.email}</div>
            <div className="col-3">phone:</div>
            <div className="col-9">{orderItem.delivery.phone}</div>
            <div className="col-3">Address:</div>
            {orderItem.delivery.address && (
              <div className="col-9">
                {orderItem.delivery.address.line1}
                <br />
                {orderItem.delivery.address.suburb}{" "}
                {orderItem.delivery.address.state}{" "}
                {orderItem.delivery.address.postcode}
              </div>
            )}
          </div>
        </div>
      )}
      {orderItem && orderItem._id && (
        <div className="card-header">
          {orderItem._id && (
            <div className="row">
              <div className="col-3">Order ID: </div>
              <div className="col-9 text-end">{orderItem._id}</div>
            </div>
          )}

          <div className="row">
            <div className="col-3">PaymentID: </div>
            <div className="col-9 text-end">
              {orderItem.payId ? orderItem.payId : ""}
            </div>{" "}
          </div>
          <div className="row">
            <div className="col-3">ReceiptNr: </div>
            <div className="col-9 text-end">
              {orderItem.receipt ? orderItem.receipt : ""}
            </div>
          </div>
          {orderItem.paidDate && (
            <div className="row">
              <div className="col-3">Paid:</div>
              <div className="col-9 text-end">
                {new Date(orderItem.paidDate).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                }) +
                  ", " +
                  new Date(orderItem.paidDate).toLocaleDateString("en-AU")}
              </div>
            </div>
          )}
          {orderItem.deliveredDate && (
            <div className="row">
              <div className="col-3">Delivered:</div>
              <div className="col-9 text-end">
                {new Date(orderItem.deliveredDate).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                }) +
                  ", " +
                  new Date(orderItem.deliveredDate).toLocaleDateString("en-AU")}
              </div>
            </div>
          )}
        </div>
      )}
      <div className="card-body">
        {orderItem &&
          orderItem.productList &&
          orderItem.productList.map((productItem) => {
            return (
              <div key={productItem.product_id} className="row">
                <div className="col-9">
                  {/* {admin && productItem.shelf_pos + ': '} */}
                  {productItem.name}
                  {productItem.quantity > 1 && (
                    <div className="text-muted">
                      &emsp; Qty' {productItem.quantity} @ ${productItem.price}/
                      {productItem.unit}
                    </div>
                  )}
                </div>
                <div className="col-3 text-end">
                  ${(productItem.price * productItem.quantity).toFixed(2)}
                </div>
              </div>
            );
          })}
      </div>
      <div className="card-footer font-italic">
        <div className="container">
          <div className="row">
            <div className="col-9 text-end">Postage:</div>
            <b className="col-3 text-end">
              ${(orderItem.postage || 0).toFixed(2)}
            </b>
          </div>
          <div className="row">
            <b className="col-9 text-end">Total:</b>
            <b className="col-3 text-end">
              ${orderItem.orderTotal && orderItem.orderTotal.toFixed(2)}
            </b>
          </div>
          <div className="row text-muted">
            <div className="col-9 text-end">incl GST:</div>
            <div className="col-3 text-end">
              ${(orderItem.GSTTotal || 0).toFixed(2)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
