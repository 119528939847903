import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FaList } from "react-icons/fa";

import OrderItem from "./OrderItem";
import OrderInterface from "./OrderInterface";
import UserInterface from "../User/UserInterface";
import authContext from "../../context/authContext";

type props = {
  user: UserInterface;
};

export default function OrdersByUserModal({ user }: props) {
  const { token } = useContext(authContext);
  const [orders, setOrders] = useState([] as OrderInterface[]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const result = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + `/order/by_user_id/${user._id}`,
          {
            headers: { "x-access-token": token },
          }
        );

        setOrders([...result.data]);
      } catch (error) {
        console.error(error);
      }
    };

    if (!!token) {
      fetchOrders();
    }
  }, [token, user]);

  return (
    <>
      <button className="btn btn-link" onClick={() => setShowModal(true)}>
        <FaList />
      </button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {"Orders by " + user.fname + " " + user.lname}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid mt-3">
            {orders.map((item) => {
              return <OrderItem key={item._id} orderItem={item} />;
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
