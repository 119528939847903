import React, { useContext } from "react";
import { useParams } from "react-router-dom";

import ProductItem from "./ProductItem";
import productContext from "../../context/productContext";

export default function ProductsByCat() {
  const { cat } = useParams();
  const { products, catList } = useContext(productContext);

  const selectedCat = catList.find(
    (item) => item.name!.replace(/\s/g, "") === cat
  );
  const productsByCat = products.filter(
    (item) =>
      selectedCat && item.category_id === selectedCat._id && item.onShelf
  );

  return (
    <div className="container">
      {selectedCat && (
        <>
          <h1>{selectedCat.name}</h1>
          <p>{selectedCat.info}</p>
        </>
      )}
      <div className="row row-cols-2 row-cols-md-4 row-cols-lg-6 g-1">
        {productsByCat.map((product) => {
          return <ProductItem key={product._id} product={product} />;
        })}
      </div>
    </div>
  );
}
