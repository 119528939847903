import React, { useContext } from 'react';

import ProjectPartners from './ProjectPartners';
import authContext from '../../context/authContext';

export default function AboutUs() {
  const { langEn } = useContext(authContext);

  return (
    <div className='row m-0'>
      <div className='col-lg-2'>
        <ProjectPartners />
      </div>
      <div className='col-lg-10'>
        {langEn ? <h1>Research Team</h1> : <h1>团队</h1>}

        <p>
          The BeFAQT team comprises researchers, developers, PhD students, and
          managers from UTS and Sydney Fish Market. Contact:{' '}
          <a href='mailto:renping.liu@uts.edu.au'>Ren Ping Liu</a>
        </p>
        <h5>
          *** Congratulations! BeFAQT was the{' '}
          <a
            href='https://aiia.com.au/iaward/2020-nsw-iawards-winners/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Winner of 2020 NSW iAwards
          </a>{' '}
          ***
        </h5>
        <img width='90%' src='/img/BeFAQTiAwardTeam.jpg' alt='ph' />
        <h3>University of Technology Sydney</h3>
        <div className='row p-2'>
          <div className='card col-lg-2 col-md-4 col-6 p-0'>
            <img
              className='card-img'
              width='100px'
              src='/img/photo_ren.png'
              alt='ph'
            />
            <div className='card-footer mt-auto'>Prof. Ren Ping Liu</div>
          </div>
          <div className='card col-lg-2 col-md-4 col-6 p-0'>
            <img
              className='card-img'
              width='100px'
              src='/img/photo_xu.png'
              alt='ph'
            />
            <div className='card-footer mt-auto'>Dr. Xu Wang</div>
          </div>
          <div className='card col-lg-2 col-md-4 col-6 p-0'>
            <img
              className='card-img'
              width='100px'
              src='/img/photo_jian.png'
              alt='ph'
            />
            <div className='card-footer mt-auto'>A/Prof. Jian Zhang</div>
          </div>
          <div className='card col-lg-2 col-md-4 col-6 p-0'>
            <img
              className='card-img'
              width='100px'
              src='/img/photo_steven.png'
              alt='ph'
            />
            <div className='card-footer mt-auto'>A/Prof Steven Su</div>
          </div>
          <div className='card col-lg-2 col-md-4 col-6 p-0'>
            <img
              className='card-img'
              width='100px'
              src='/img/photo_ying.png'
              alt='ph'
            />
            <div className='card-footer mt-auto'>Dr. Ying He</div>
          </div>
          <div className='card col-lg-2 col-md-4 col-6 p-0'>
            <img
              className='card-img'
              width='100px'
              src='/img/photo_qiang.png'
              alt='ph'
            />
            <div className='card-footer mt-auto'>A/Prof. Qiang Wu</div>
          </div>
          <div className='card col-lg-2 col-md-4 col-6 p-0'>
            <img
              className='card-img'
              width='100px'
              src='/img/photo_eryk.png'
              alt='ph'
            />
            <div className='card-footer mt-auto'>Prof. Eryk Dutkiewicz</div>
          </div>
          <div className='card col-lg-2 col-md-4 col-6 p-0'>
            <img
              className='card-img'
              width='100px'
              src='/img/photo_peter.png'
              alt='👤'
            />
            <div className='card-footer mt-auto'>Peter Loneragan</div>
          </div>
          <div className='card col-lg-2 col-md-4 col-6 p-0'>
            <img
              className='card-img'
              width='100px'
              src='/img/photo_saber.png'
              alt='ph'
            />
            <div className='card-footer mt-auto '>Guangsheng Yu</div>
          </div>
          <div className='card col-lg-2 col-md-4 col-6 p-0'>
            <img
              className='card-img'
              width='100px'
              src='/img/photo_zongjian.png'
              alt='ph'
            />
            <div className='card-footer mt-auto'>Dr. Zongjian Zhang</div>
          </div>
          <div className='card col-lg-2 col-md-4 col-6 p-0'>
            <img
              className='card-img'
              width='100px'
              src='/img/photo_wentian.png'
              alt='👤'
            />
            <div className='card-footer mt-auto'>Wentian Zhang</div>
          </div>
          <div className='card col-lg-2 col-md-4 col-6 p-0'>
            <img
              className='card-img'
              width='100px'
              src='/img/photo_taoping.png'
              alt='ph'
            />
            <div className='card-footer mt-auto'>Taoping Liu</div>
          </div>
        </div>
        <h3>Sydney Fish Market</h3>
        <p>Erik Poole and Nick Paton</p>
        <h3>List of Publications</h3>
        <ol>
          <li>
            Guangsheng Yu, Xuan Zha, Xu Wang, Wei Ni, Kan Yu, J. Andrew Zhang,
            Ren Ping Liu, "A Unified Analytical model for proof-of-X schemes,"
            Computers & Security,2020
          </li>
          <li>
            Guangsheng Yu, Xuan Zha, Xu Wang, Wei Ni, Kan Yu, Ping Yu, J.
            Andrew, Ren Ping Liu, Y. Jay Guo, "Enabling Attribute Revocation for
            Fine-Grained Access Control in Blockchain-IoT Systems," IEEE
            Transactions on Engineering Management, Feb. 2020.
          </li>
          <li>
            Guangsheng Yu, Xu Wang, Kan Yu, Wei Ni, J. Andrew Zhang, Ren Ping
            Liu, "Survey: Sharding in Blockchains," IEEE Access, 2020
          </li>
          <li>
            Xu Wang, Ping Yu, Guangsheng Yu, Xuan Zha, Wei Ni, Ren Ping Liu, Y.
            Jay Guo, "A High-Performance Hybrid Blockchain System for Traceable
            IoT Applications," International Conference on Network and System
            Security, December 2019.
          </li>
          <li>
            Wentian Zhang et al. A novel data pre-processing method for odour
            detection and identification system. Sensors and Actuators A:
            Physical, 287:113–120, 2019.
          </li>
          <li>
            Taoping Liu et al. A novel multi-odour identification by electronic
            nose using non-parametric modelling-based feature extraction and
            time-series classification. Sensors and Actuators B: Chemical, 2019.
          </li>
          <li>
            Huaxi Huang et al. “Compare More Nuanced: Pairwise Alignment
            Bilinear Network for Few-Shot Fine-Grained Learning,” IEEE
            International Conference on Multimedia and Expo, ICME 2019,
            pp.91-96.
          </li>
        </ol>
      </div>
    </div>
  );
}
