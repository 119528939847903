import React, { useContext } from "react";

import CartItem from "./CartItem";
import CartTotals from "./CartTotals";
import authContext from "../../context/authContext";
import CartContext from "../../context/CartContext";
import CartCheckout from "./CartCheckout";

function Cart() {
  const { langEn } = useContext(authContext);
  const { cart } = useContext(CartContext);

  return (
    <>
      <h1>{langEn ? "cart" : "购物车"}</h1>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6">
            {cart.map((item) => {
              return <CartItem key={item.product_id} cartItem={item} />;
            })}
            <div className="col-12">
              <CartTotals />
            </div>
          </div>
          <div className="col-lg-4 mt-3">
            <CartCheckout />
          </div>
        </div>
      </div>
    </>
  );
}

export default Cart;
