import React, { useContext, useEffect, useState } from "react";
import { FaStore } from "react-icons/fa";
import { Link } from "react-router-dom";

import authContext from "../../context/authContext";
import CartContext from "../../context/CartContext";

const ORDER_DELIVERY = 0;

export default function CartTotals() {
  const { langEn } = useContext(authContext);
  const { cart, cartCostTotal, setCartCostTotal } = useContext(CartContext);

  const [cartPriceTotal, setCartPriceTotal] = useState(0);
  const [cartDiscTotal, setDiscTotal] = useState(0);
  const [cartGSTTotal, setCartGSTTotal] = useState(0);

  useEffect(() => {
    let _cartPriceTotal = 0;
    let _cartDiscTotal = 0;
    let _cartCostTotal = 0;
    let _cartGSTTotal = 0;

    cart.forEach((cartItem) => {
      _cartPriceTotal += cartItem.price * cartItem.quantity;
      _cartCostTotal +=
        cartItem.price *
        (1 - (cartItem.discount || 0) / 100.0) *
        cartItem.quantity;
      _cartDiscTotal +=
        cartItem.price * ((cartItem.discount || 0) / 100) * cartItem.quantity;
      _cartGSTTotal +=
        (cartItem.includeGST
          ? (cartItem.price * (1 - (cartItem.discount || 0) / 100.0)) / 11.0
          : 0) * cartItem.quantity;
    });

    setCartPriceTotal(_cartPriceTotal); // total price, not including discount
    setDiscTotal(_cartDiscTotal);
    setCartCostTotal(_cartCostTotal + ORDER_DELIVERY); // updating checkout amount in Stripe button.
    setCartGSTTotal(_cartGSTTotal);
  }, [cart, setCartCostTotal]);

  return cartCostTotal > ORDER_DELIVERY ? (
    <div className="text-end">
      <div>
        <div>Subtotal: ${cartPriceTotal.toFixed(2)}</div>
        <div>Delivery: ${ORDER_DELIVERY.toFixed(2)}</div>
        <h5>
          <span>
            {langEn ? "Total" : "总计"}: ${cartCostTotal.toFixed(2)}
          </span>
        </h5>
        <div className="text-muted">
          {langEn ? "Incl. GST" : "含消费税"}: ${cartGSTTotal.toFixed(2)}
        </div>
        {cartDiscTotal > 0 && (
          <>
            <div>*************************************</div>
            <div>Total Savings: ${cartDiscTotal.toFixed(2)} </div>
            <div>*************************************</div>
          </>
        )}
      </div>
      <Link to="/">
        <button className="btn btn-outline-primary mr-2">
          <h5>
            <FaStore />
            <span>{langEn ? "Back to Shop" : "回到商店"}</span>
          </h5>
        </button>
      </Link>
    </div>
  ) : (
    <h3>Cart is empty</h3>
  );
}
