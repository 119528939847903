import React, { useState, useEffect } from "react";

import MapMarkersLinked from "../Map/MapMarkersLinked";
import MarkerInterface, { LocInterface } from "../Map/MarkerInterface";
import { IoTTrackingIntrface } from "./SFMBoxInterface";

type props = {
  iotTracking: IoTTrackingIntrface[];
};

export default function SFMBoxDetailsTrackMap({ iotTracking }: props) {
  const [markers, setMarkers] = useState<MarkerInterface[]>([]);
  const [markersCenter, setMarkersCenter] = useState<LocInterface>();

  useEffect(() => {
    let markersArr: MarkerInterface[] = [];
    let latSum = 0;
    let lngSum = 0;
    // let _latitudePrev = 0;
    // let _longitudePrev = 0;
    iotTracking.forEach((item) => {
      // set markers array for the Map with multi markers

      let _marker: MarkerInterface = {
        location: {
          lat: item.latitude,
          lng: item.longitude,
        },
        title:
          "iotID: " +
          item.iotID +
          "\n" +
          "Temperature: " +
          item.temperature.toString(),
        label: item.temperature.toFixed(2).toString(),
      };

      if (Math.abs(_marker.location.lat) > 0) {
        markersArr.push(_marker);
        latSum += _marker.location.lat;
        lngSum += _marker.location.lng;
        // _latitudePrev = item.latitude;
        // _longitudePrev = item.longitude;
      } else {
        // if lat=lng=0, change it to previous values.
        // item.latitude = _latitudePrev;
        // item.longitude = _longitudePrev;
      }
    });
    setMarkers(markersArr);
    setMarkersCenter({
      lat: latSum / markersArr.length,
      lng: lngSum / markersArr.length,
    });
  }, [iotTracking]);

  return (
    <div>
      {markers.length > 0 && markersCenter && (
        <>
          <MapMarkersLinked
            center={markersCenter}
            zoom={8}
            markers={markers}
            linked={true}
          />
        </>
      )}
    </div>
  );
}
