import React, { useContext } from "react";
import { FaTrashAlt } from "react-icons/fa";

import "./CartItem.css";
import CartInterface from "./CartInterface";
import CartContext from "../../context/CartContext";

type CartProps = {
  index?: string;
  cartItem: CartInterface;
};

export default function CartItem({ cartItem }: CartProps) {
  const { product_id, name, image, price, unit, shelfPos, quantity } = cartItem;

  const { addToCart, removeFromCart, deleteProductFromCart } =
    useContext(CartContext);
  return (
    <div className="row">
      <div className="col-2">
        <img
          src={process.env.REACT_APP_ASSET_URL + "/" + image}
          style={{ width: "3rem", height: "3rem" }}
          alt="product"
        />
      </div>
      <div className="col-4 d-flex">
        <div>
          {shelfPos}: {name}
          <p className="text-muted">
            ${price}/{unit}
          </p>
        </div>
      </div>

      <div className="col-4">
        <div>
          <span
            className="btn cart-btn-minus"
            onClick={() => removeFromCart(product_id)}
          >
            -
          </span>
          <span className="btn cart-btn-num">{quantity}</span>
          <span
            className="btn cart-btn-add"
            onClick={() => addToCart(product_id)}
          >
            +
          </span>
        </div>
      </div>
      <div className="col-1 text-right">${(price * quantity).toFixed(2)}</div>
      <div className="col-1">
        <button
          className="btn cart-icon"
          title="remove this product"
          onClick={() => deleteProductFromCart(product_id)}
        >
          <FaTrashAlt />
        </button>
      </div>
    </div>
  );
}
