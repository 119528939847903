import React from "react";
import { Navigate } from "react-router-dom";

const AdminRoute = ({ children }: { children: JSX.Element }) => {
  const localToken = JSON.parse(
    localStorage.getItem(
      process.env.REACT_APP_LS_TOKEN ? process.env.REACT_APP_LS_TOKEN : "token"
    )!
  );
  if (localToken && localToken.token && localToken.user_role === "admin") {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

export default AdminRoute;
