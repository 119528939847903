import React, { useState, useEffect, PropsWithChildren } from "react";
import Axios from "axios";

import ProductContext from "./productContext";
import CategoryInterface from "../components/Category/CategoryInterface";
import ProductInterface from "../components/Product/ProductInterface";

const ProductProvider = (props: PropsWithChildren) => {
  const [products, setProducts] = useState<ProductInterface[] | []>([]);
  const [onShelfProducts, setOnShelfProducts] = useState<
    ProductInterface[] | []
  >([]);

  const [categories, setCategories] = useState<CategoryInterface[] | []>([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setIsLoading(true);
        const resProd = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + "/product"
        );
        console.log("product: ", resProd);
        let prodArray: ProductInterface[] = resProd.data;

        prodArray.sort(
          (a, b) =>
            +b.onShelf! - +a.onShelf! ||
            +a.shelfPos!.split(".")[0] - +b.shelfPos!.split(".")[0] ||
            +a.shelfPos!.split(".")[1] - +b.shelfPos!.split(".")[1] ||
            +a.shelfPos!.split(".")[2] - +b.shelfPos!.split(".")[2]
        );

        setProducts([...prodArray]);
        setOnShelfProducts([...prodArray.filter((item) => item.onShelf)]);

        const resCat = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + "/category"
        );
        let catsArray: CategoryInterface[] = resCat.data;
        // sort categories using Category.shelfPos: string
        // SFMBox: a special category with shelfPos="0" - shown at "Catch of the Day" at Home page
        // catsArray.length > 0 &&
        catsArray.sort((a, b) => a.shelfPos! - b.shelfPos!);
        setCategories([...catsArray]);

        // const resSubCat = await Axios.get(
        //   process.env.REACT_APP_BACKEND_URL + "/subCats"
        // );
        // setSubCats([...resSubCat.data]);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };
    fetchProducts();
  }, []);

  return (
    <ProductContext.Provider
      value={{
        catList: categories,
        products,
        onShelfProducts,
        categories,
        isLoading,
      }}
    >
      {props.children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;
