import React, { Fragment, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Axios from "axios";

// Source: https://blog.bitsrc.io/email-confirmation-with-react-257e5d9de725

export default function UserActivation() {
  const { emailToken } = useParams();
  const [userEmail, setUserEmail] = useState("");
  const [updated, setUpdated] = useState<boolean>(false);
  const [errorRes, setErrorRes] = useState<string>();

  async function activateUser(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      const doc = await Axios.get(
        process.env.REACT_APP_BACKEND_URL + "/user/activate/" + emailToken,
        {
          params: { email: userEmail },
        }
      );
      if (doc.status === 200) {
        setUpdated(true);
      }
    } catch (error) {
      console.error(error);
      setErrorRes(error.message);
    }
  }

  return (
    <div>
      {errorRes ? (
        <div className="container text-center">
          <h1>Activate User</h1>
          <h3>Error: {errorRes} </h3>
          <Link className="btn btn-warning" to="/">
            Return
          </Link>
        </div>
      ) : (
        <div className="container">
          {updated ? (
            <>
              <h1>Activate User Success</h1>
              <div className="text-center">
                <h5>Your account has been successfully activated.</h5>
                <h5>Please login.</h5>
              </div>
            </>
          ) : (
            <div className="text-center">
              <h1>Activate User</h1>
              <form onSubmit={activateUser}>
                <div className="row m-3">
                  <h5 className="col text-end">User Email:</h5>
                  <div className="col">
                    <input
                      className="form-control"
                      type="email"
                      onChange={(e) => setUserEmail(e.target.value)}
                      placeholder="📧 email"
                    />
                  </div>
                </div>
                <button className="btn btn-warning">Activate</button>
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
