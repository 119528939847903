import React, { useContext } from "react";
import axios from "axios";
import { FaEdit, FaTrashAlt } from "react-icons/fa";

import authContext from "../../context/authContext";
import ProductInterface from "../Product/ProductInterface";
import CategoryInterface from "../Category/CategoryInterface";

type props = {
  index: number;
  product: ProductInterface;
  setEdit: (product_id: string) => void;
  cat: CategoryInterface;
};

export default function ProductManageItem({
  index,
  product,
  setEdit,
  cat,
}: props) {
  const { token } = useContext(authContext);

  async function deleteProd(product_id: string) {
    try {
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + "/product/" + product_id,
        {
          headers: { "x-access-token": token },
        }
      );
    } catch (error) {
      console.error(error);
    }

    window.location.reload();
  }

  return (
    <>
      <div className={index % 2 ? "row mt-2 bg-white" : "row mt-2"}>
        <div className="col-1">{product.name}</div>
        <div className="col-1">
          <span className="d-lg-none">名称: </span>
          {product.nameCN}
        </div>
        <div className="col-1">{product.brand}</div>
        <div className="col-1 text-left">
          <span className="d-lg-none">category: </span>
          {cat && cat.name}
        </div>
        <div className="col-1 text-center">
          <img
            src={
              product.images &&
              process.env.REACT_APP_ASSET_URL + "/" + product.images[0]
            }
            style={{ width: "3rem", height: "2rem" }}
            className="img-fluid"
            alt="product"
          />
        </div>
        <div className="col-1 text-end">${product.price}</div>
        <div className="col-1">{product.unit}</div>
        <div className="col-1 text-center">
          <input type="checkbox" checked={product.includeGST} disabled />
        </div>
        <div className="col-1 text-end">{product.stock}</div>
        <div className="col-1 text-center">
          <input type="checkbox" checked={product.onShelf} disabled />
        </div>
        <div className="col-1">{product.shelfPos}</div>
        <div className="col-1">
          <button
            className="btn btn-link"
            onClick={() => product._id && setEdit(product._id)}
          >
            <FaEdit />
          </button>
          {!product.onShelf && (
            <button
              className="btn btn-link"
              onClick={() => product._id && deleteProd(product._id)}
            >
              <FaTrashAlt />
            </button>
          )}
        </div>
      </div>
      <div className={index % 2 ? "row bg-white" : "row"}>
        <div className="col-11">
          {product.info && product.info.substring(0, 120) + "..."}
        </div>
      </div>
    </>
  );
}
