import React from "react";

import MapGeocoder from "../Map/MapGeocoder";
import { ImageProcInterface } from "./SFMBoxInterface";

type props = {
  imageProc: ImageProcInterface[];
};

export default function SFMBoxDetailsImgProc({ imageProc }: props) {
  return (
    <div>
      <h3>E-eye Records</h3>
      {imageProc.map((item, key) => {
        return (
          <div key={key}>
            <ul style={{ listStyleType: "none" }}>
              <li>Operator: {item.operator}</li>
              <li>Freshness Score: {item.imgScore}</li>
              {/* <li>Confidence: {item.confidence}</li> */}
              <li>
                Date, Time: {item.time.split(" ")[0]},{" "}
                {item.time.split(" ")[1].substr(0, 5)}
              </li>
              <li>
                Address:{" "}
                <MapGeocoder lat={item.latitude} lng={item.longitude} />
              </li>
            </ul>
            <div className="row">
              {item.resultPerImage.map((item, index) => {
                return (
                  <div className="col-md-4" key={index}>
                    <div className="row">
                      <div className="col-6">
                        <ul>
                          <li>fish score: {item.fish} </li>
                          <li>eye score: {item.eye}</li>
                          <li>skin score: {item.skin}</li>
                          <li>condidence: {item.confidence}</li>
                        </ul>
                      </div>
                      <div className="col-6">
                        <a href={item.image}>
                          <img
                            src={item.image}
                            alt="img"
                            className="img-fluid"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
