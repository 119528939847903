import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
import PrivateRoute from "./components/Routes/PrivateRoute";
import AdminRoute from "./components/Routes/AdminRoute";
import AuthProvider from "./context/authProvider";
import ProductProvider from "./context/productProvider";
import ShopNavbar from "./components/ShopNavbar";
import AboutUs from "./components/About/AboutUs";
import AboutProject from "./components/About/AboutProject";
import AboutTech from "./components/About/AboutTech";
import ProductCategories from "./components/Product/ProductCategories";
// import SFMBoxes from "./components/SFMBox/SFMBoxes";
import SFMBoxDetails from "./components/SFMBox/SFMBoxDetails";
import ProductByCat from "./components/Product/ProductsByCat";
import ProductSearch from "./components/Product/ProductSearch";
import ProductDetails from "./components/Product/ProductDetails";
import ProductManage from "./components/ProductManage/ProductManage";
import Cart from "./components/Cart/Cart";
import Contact from "./components/Contact/Contact";
import OrderCheckout from "./components/Order/OrderCheckout";
import MyOrders from "./components/Order/MyOrders";
import OrderManage from "./components/Order/OrderManage";
import CategoryManage from "./components/Category/CategoryManage";
import UserActivation from "./components/User/UserActivation";
import UserUpdate from "./components/User/UserUpdate";
import UserManage from "./components/UserManage/UserManage";
import Header from "./components/Header";
import CartProvider from "./context/CartProvider";

function App() {
  return (
    <AuthProvider>
      <ProductProvider>
        <CartProvider>
          <Router>
            <Fragment>
              <Header />
              <ShopNavbar />
              <Routes>
                <Route path="/" element={<ProductCategories />} />
                <Route path="/about/us" element={<AboutUs />} />
                <Route path="/about/project" element={<AboutProject />} />
                <Route path="/about/tech" element={<AboutTech />} />
                <Route path="/products" element={<ProductCategories />} />
                <Route path="/products/search" element={<ProductSearch />} />
                <Route path="/contact" element={<Contact />} />
                {/* <Route path="/SFMBoxes/all" element={<SFMBoxes />} /> */}
                <Route path="/SFMBoxes/byId/:_id" element={<SFMBoxDetails />} />
                <Route path="/productsbycat/:cat" element={<ProductByCat />} />
                <Route
                  path="/productbyid/:productId"
                  element={<ProductDetails />}
                />
                <Route path="/cart" element={<Cart />} />
                <Route
                  path="/user/activate/:emailToken"
                  element={<UserActivation />}
                />
                <Route
                  path="/user/update"
                  element={
                    <PrivateRoute>
                      <UserUpdate />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/cartcheckout"
                  element={
                    <PrivateRoute>
                      <OrderCheckout />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/order/myorders"
                  element={
                    <PrivateRoute>
                      <MyOrders />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/product/manage"
                  element={
                    <AdminRoute>
                      <ProductManage />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/user/manage"
                  element={
                    <AdminRoute>
                      <UserManage />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/order/manage"
                  element={
                    <AdminRoute>
                      <OrderManage />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/category/manage"
                  element={
                    <AdminRoute>
                      <CategoryManage />
                    </AdminRoute>
                  }
                />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Fragment>
          </Router>
        </CartProvider>
      </ProductProvider>
    </AuthProvider>
  );
}

export default App;
