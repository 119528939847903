import React from "react";

export default function UsersColumns() {
  return (
    <div className="row">
      <div className="col-2">Name</div>
      <div className="col-2">email</div>
      <div className="col-1">role</div>
      <div className="col-2">phone</div>
      <div className="col-3">address</div>
      <div className="col-1">status</div>

      <div className="col-1">orders | edit</div>
    </div>
  );
}
