import React, { useState, useContext } from "react";

import ProductManageColumns from "./ProductManageColumns";
import ProductManageItem from "./ProductManageItem";
import ProductManageAdd from "./ProductManageAdd";
import ProductManageEdit from "./ProductManageEdit";
import authContext from "../../context/authContext";
import productContext from "../../context/productContext";

export default function ProductManage() {
  const { langEn } = useContext(authContext);
  const { products, categories } = useContext(productContext);

  const [editProductId, setEditProductId] = useState("");
  const setEdit = (product_id: string) => {
    setEditProductId(product_id);
  };

  return (
    <div className="container-fluid">
      <h1>{langEn ? "Product Management" : "商品管理"} </h1>
      <div className="container-fluid">
        <ProductManageColumns />
        <ProductManageAdd />
        {products.map((product, index) => {
          if (product._id === editProductId) {
            return (
              <ProductManageEdit
                key={product._id}
                index={index}
                product={product}
                categories={categories}
              />
            );
          } else {
            return (
              <ProductManageItem
                key={product._id}
                index={index}
                product={product}
                setEdit={setEdit}
                cat={
                  categories.find((item) => item._id === product.category_id)!
                }
              />
            );
          }
        })}
      </div>
    </div>
  );
}
