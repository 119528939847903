import React, { useState, useContext } from "react";
import axios from "axios";
import { FaSave } from "react-icons/fa";

import authContext from "../../context/authContext";
import CategoryInterface from "./CategoryInterface";

type Props = {
  index: number;
  cat: CategoryInterface;
};

export default function CategoryEdit({ index, cat }: Props) {
  const { token } = useContext(authContext);
  const [editCat, setEditCat] = useState(cat);

  const [imgFile, setImgFile] = useState<File | null>(null);
  const handleSelectedImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImgFile(e.target.files && e.target.files[0]);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditCat((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setEditCat((prevEx) => {
      return {
        ...prevEx,
        [name]: checked,
      };
    });
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const uploadCat = new FormData();
    if (imgFile) {
      uploadCat.append("catImg", imgFile);
    }
    Object.keys(editCat).forEach(function (k) {
      uploadCat.append(k, editCat[k as keyof CategoryInterface] as string);
    });

    try {
      await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/category/" + cat._id,
        uploadCat,
        {
          headers: { "x-access-token": token },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload();
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={index % 2 ? "row mt-1 bg-white" : "row mt-1"}>
        <div className="col-1">
          <input
            className="form-control"
            type="text"
            name="name"
            value={editCat.name}
            onChange={handleChange}
          />
        </div>
        <div className="col-1">
          <input
            className="form-control"
            type="text"
            name="nameCN"
            value={editCat.nameCN}
            onChange={handleChange}
          />
        </div>

        <div className="col-1">
          <input
            className="form-control"
            type="text"
            name="shelfPos"
            value={editCat.shelfPos?.toString()}
            onChange={handleChange}
          />
        </div>

        <div className="col-2">
          <input
            className="form-control"
            type="file"
            name="image"
            onChange={handleSelectedImage}
          />
        </div>

        <div className="col-5">
          <input
            className="form-control"
            type="text"
            name="info"
            value={editCat.info}
            onChange={handleChange}
          />
        </div>

        <div className="col-1">
          <input
            className="form-check-input"
            type="checkbox"
            name="active"
            checked={editCat.active}
            onChange={handleCheck}
          />
        </div>

        <div className="col-1">
          <button className="btn btn-link" type="submit">
            <FaSave />
          </button>
        </div>
      </div>
    </form>
  );
}
