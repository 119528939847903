import React, { useContext } from "react";

import authContext from "../../context/authContext";

export default function Contact() {
  const { langEn } = useContext(authContext);

  return (
    <>
      <h1>{langEn ? <span>CONTACT US</span> : "联系"}</h1>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 my-3">
            <h3>Contact</h3>
            <div
              className="container p-3"
              style={{
                color: "black",
                backgroundColor: "var(--mainWhite)",
                boxShadow: "1px 1px",
              }}
            >
              Professor Ren Ping LIU
              <br />
              Faculty of Engineering and IT
              <br />
              University of Technology Sydney
              <br />
              UTS Building 11, 81 Broadway, Ultimo NSW 2007
              <br />
              Email:{" "}
              <a href="mailto:renping.liu@uts.edu.au">renping.liu@uts.edu.au</a>
            </div>
          </div>
          <div className="col-lg-6 my-3">
            <h3>Map</h3>
            <iframe
              title="UTS Faculty of Engineering and IT"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3312.2194094338934!2d151.19709251521064!3d-33.88400268065206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae262d1ca223%3A0x5aa7383337c73213!2sUTS%20Faculty%20of%20Engineering%20and%20IT!5e0!3m2!1sen!2sau!4v1602307979233!5m2!1sen!2sau"
              width="100%"
              height="450"
              // frameborder="0"
              style={{ border: "5px", boxShadow: "1px 1px #232528" }}
              // allowfullscreen=""
              aria-hidden="false"
              // tabindex="0"
            />
          </div>
        </div>
      </div>
    </>
  );
}
