import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import authContext from "../../context/authContext";

import UserColumn from "./UserColumn";
import UserItem from "./UserItem";
import UserEdit from "./UserEdit";
import UserInterface from "../User/UserInterface";

export default function UserManage() {
  const { token, langEn } = useContext(authContext);

  const [allUsers, setAllUsers] = useState<UserInterface[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const resDoc = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user",
          {
            headers: { "x-access-token": token },
          }
        );
        let userList: UserInterface[] = resDoc.data;
        userList.sort(
          (a, b) =>
            (b.role && a.role && +b.role - +a.role) || +a.lname! - +b.lname!
        );

        setAllUsers([...userList]);
      } catch (error) {
        console.error(error);
      }
    };
    token && fetchUsers();
  }, [token]);

  const [editUserId, setEditUserId] = useState("");
  const setEdit = (user_id: string) => {
    setEditUserId(user_id);
  };

  return (
    <div className="container-fluid">
      <h1>{langEn ? "User Management" : "用户管理"} </h1>
      <div className="container-fluid">
        <UserColumn />
        {allUsers.map((user, index) => {
          if (user._id === editUserId) {
            return <UserEdit key={user._id} index={index} user={user} />;
          } else {
            return (
              <UserItem
                key={user._id}
                index={index}
                user={user}
                setEdit={setEdit}
              />
            );
          }
        })}
      </div>
    </div>
  );
}
