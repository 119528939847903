/* eslint-disable no-lone-blocks */
import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";

import OrderItem from "./OrderItem";
import authContext from "../../context/authContext";
import OrderInterface from "./OrderInterface";

function MyOrders() {
  const { token, langEn } = useContext(authContext);
  const [orders, setOrders] = useState([] as OrderInterface[]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const result = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + "/order/by_token",
          {
            headers: { "x-access-token": token },
          }
        );

        setOrders([...result.data]);
      } catch (error) {
        console.error(error);
      }
    };

    if (!!token) {
      fetchOrders();
    }
  }, [token]);

  return (
    <React.Fragment>
      <h1>{langEn ? "My Orders" : "我的订单"} </h1>
      <div className="container-fluid mt-3">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-2">
          {orders.map((item) => {
            return <OrderItem key={item._id} orderItem={item} />;
          })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default MyOrders;
