import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import axios from 'axios';

import SFMBoxDetailsEnose from "./SFMBoxDetailsEnose";
import SFMBoxDetailsImgProc from "./SFMBoxDetailsImgProc";
import SFMBoxDetailsIoT from "./SFMBoxDetailsIoT";
import SFMBoxDetailsFisher from "./SFMBoxDetailsFisher";
import SFMBoxDetailsProv from "./SFMBoxDetailsProv";
import SFMBoxDetailsFresh from "./SFMBoxDetailsFresh";
import SFMBoxDetailsTrackMap from "./SFMBoxDetailsTrackMap";
import SFMBoxData from "./SFMBoxData";
import SFMBoxInterface from "./SFMBoxInterface";

export default function SFMBoxDetails() {
  const { boxID } = useParams();
  const [box, setBox] = useState<SFMBoxInterface>();

  useEffect(() => {
    async function fetchBox() {
      // let res = await axios.get(
      //   // process.env.REACT_APP_BACKEND_URL + '/sfmBoxes/byId/' + boxID
      //   process.env.REACT_APP_BEFAQT_API + '?boxid=0345579444'
      // );
      // console.log('Box Record: ', res.data.content);
      // setBox(res.data.content);
      setBox(SFMBoxData as SFMBoxInterface);
    }
    fetchBox();
  }, [boxID]);

  return (
    <div className="container-fluid">
      {box && (
        <>
          <h1>"Sydney Fish Market Box: #" name={box.boxID} </h1>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-6">
                  {box.fisher && <SFMBoxDetailsFisher fisher={box.fisher} />}
                </div>

                <div className="col-6">
                  {box.freshness && box.iotResult && (
                    <SFMBoxDetailsFresh
                      freshness={box.freshness}
                      iotResult={box.iotResult}
                      imageProc={box.imageProc && box.imageProc[0]}
                      e_nose={box.e_nose[0]}
                    />
                  )}
                </div>

                <div className="col-6 mt-3">
                  {box.catchProv && (
                    <SFMBoxDetailsProv catchProv={box.catchProv} />
                  )}
                </div>

                <div className="col-6 mt-3">
                  <h5>Image: </h5>
                  <img
                    src={box.catchProv.img}
                    className="img-fluid"
                    alt="prov img"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <h5>Tracking Map</h5>
              {box.iotResult.iotTracking && (
                <SFMBoxDetailsTrackMap
                  iotTracking={box.iotResult.iotTracking}
                />
              )}
            </div>
            {/* <div className='col-3'>
          <h5>Temperature Chart</h5>
          {box.iotResult.iotTracking && (
            <SFMBoxDetailsIoTChart iotResult={box.iotResult} />
          )}
        </div> */}
          </div>
          <hr></hr>
          {box.iotResult.iotTracking && (
            <SFMBoxDetailsIoT iotResult={box.iotResult} />
          )}
          <hr></hr>
          <SFMBoxDetailsImgProc imageProc={box.imageProc} />
          <hr></hr>
          <SFMBoxDetailsEnose enose={box.e_nose} />)
        </>
      )}
    </div>
  );
}
