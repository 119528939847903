import React, { useContext } from "react";
import axios from "axios";
import { FaEdit, FaTrashAlt } from "react-icons/fa";

import authContext from "../../context/authContext";
import CategoryInterface from "./CategoryInterface";

type Props = {
  index: number;
  cat: CategoryInterface;
  setEdit: (cat_id: string) => void;
};

export default function CategoeyItem({ index, cat, setEdit }: Props) {
  const { token } = useContext(authContext);

  async function deleteCategory(productId: string) {
    try {
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + "/category/" + cat._id,
        {
          headers: { "x-access-token": token },
        }
      );
    } catch (error) {
      console.error(error);
    }

    window.location.reload();
  }

  return (
    <>
      <div className={index % 2 ? "row mt-1 bg-white" : "row mt-1"}>
        <div className="col-1 text-left">{cat.name}</div>
        <div className="col-1">{cat.nameCN}</div>

        <div className="col-1">{cat.shelfPos}</div>
        <div className="col-2">
          <img
            src={process.env.REACT_APP_ASSET_URL + "/" + cat.image}
            style={{ width: "3rem", height: "2rem" }}
            className="img-fluid"
            alt="product"
          />
        </div>

        <div className="col-5">{cat.info}</div>
        <div className="col-1">
          <input type="checkbox" checked={cat.active} disabled />
        </div>

        <div className="col-1">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              className="btn btn-link"
              onClick={() => cat._id && setEdit(cat._id)}
            >
              <FaEdit />
            </button>
            {!cat.active && (
              <button
                className="btn btn-link"
                onClick={() => cat._id && deleteCategory(cat._id)}
                // disabled
              >
                <FaTrashAlt />
              </button>
            )}
          </div>
        </div>
        {/* <div className='container-fluid row'>
          <div className='col-1'></div>
          <div className='col-11 col-lg-2'>
            <button
              className='btn btn-link'
              onClick={() => setOpenSubCat(!openSubCat)}
            >
              Sub-Categories▼
            </button>
          </div>
        </div> */}

        {/* <Collapse in={openSubCat}>
          <div className='container-fluid row'>
            <div className='col-1'></div>
            <div className='col-10'>
              <SubCatManage subCat={subCat} categoryId={_id} />
            </div>
          </div>
        </Collapse> */}
      </div>
    </>
  );
}
