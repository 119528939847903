import React, {
  useState,
  useEffect,
  PropsWithChildren,
  useContext,
} from "react";

import CartContext from "./CartContext";
import ProductInterface from "../components/Product/ProductInterface";
import CartInterface from "../components/Cart/CartInterface";
import ProductContext from "./productContext";

const CartProvider = (props: PropsWithChildren) => {
  const { products } = useContext(ProductContext);

  const [cart, setCart] = useState<CartInterface[] | []>([]);
  const [cartCostTotal, setCartCostTotal] = useState(0);

  useEffect(() => {
    // each time products are updated or the user comes back after a long time,
    // the cart contents need to be updated to reflect product changes
    // in price, stock, or onShelf status.
    let updatedCart: CartInterface[] = [];
    const updateCart = async () => {
      try {
        const _cart: CartInterface[] = JSON.parse(
          localStorage.getItem(
            process.env.REACT_APP_LS_CART
              ? process.env.REACT_APP_LS_CART
              : "cart"
          ) || "[]"
        );

        if (_cart && _cart.length > 0) {
          _cart.forEach((cartItem) => {
            const foundProduct: ProductInterface | undefined = products.find(
              (_product) => _product._id === cartItem.product_id
            );
            if (foundProduct) {
              // only add the product if it is still in product list
              if (
                foundProduct.onShelf &&
                foundProduct.stock &&
                foundProduct.stock > 0
              ) {
                // only add the product if it is still onShelf and have stock
                const newQuantity =
                  foundProduct.stock >= cartItem.quantity
                    ? cartItem.quantity
                    : foundProduct.stock;
                updatedCart.push({
                  product_id: foundProduct._id!,
                  name: foundProduct.name!,
                  image: foundProduct.images![0],
                  price: foundProduct.price!,
                  includeGST: foundProduct.includeGST!,
                  unit: foundProduct.unit!,
                  shelfPos: foundProduct.shelfPos!,
                  quantity: newQuantity,
                });
              }
            }
          });
        }

        // updatedCart.sort(
        //   (a, b) =>
        //     +a.shelfPos.split(".")[0] - +b.shelfPos.split(".")[0] ||
        //     +a.shelfPos.split(".")[1] - +b.shelfPos.split(".")[1] ||
        //     +a.shelfPos.split(".")[2] - +b.shelfPos.split(".")[2]
        // );

        setCart([...updatedCart]);
        localStorage.setItem(
          process.env.REACT_APP_LS_CART
            ? process.env.REACT_APP_LS_CART
            : "cart",
          JSON.stringify(updatedCart)
        );
      } catch (error) {
        console.error(error);
      }
    };

    if (products.length > 0) {
      updateCart();
    }
  }, [products]);

  const addToCart = (_product_id: string) => {
    const _cart = [...cart];
    const pIndex = _cart.findIndex((item) => item.product_id === _product_id);
    if (pIndex < 0) {
      // this product is not in the cart list, so add a new product to cart
      const productToBeAdded = products.find(({ _id }) => _id === _product_id);
      if (productToBeAdded) {
        _cart.push({
          product_id: productToBeAdded._id!,
          name: productToBeAdded.name!,
          image: productToBeAdded.images![0],
          price: productToBeAdded.price!,
          includeGST: productToBeAdded.includeGST!,
          unit: productToBeAdded.unit!,
          shelfPos: productToBeAdded.shelfPos!,
          quantity: 1,
        });
      } else {
        // selected product not in product list? not possible
      }
    } else {
      // productId is already in cart list, add one more.
      _cart[pIndex].quantity++;
    }

    setCart([..._cart]);
    localStorage.setItem(
      process.env.REACT_APP_LS_CART ? process.env.REACT_APP_LS_CART : "cart",
      JSON.stringify(_cart)
    );
  };

  const removeFromCart = (_product_id: string) => {
    // remove one product: quantity--
    const _cart = [...cart];
    const pIndex = _cart.findIndex((item) => item.product_id === _product_id);
    if (pIndex >= 0) {
      if (_cart[pIndex].quantity > 1) {
        _cart[pIndex].quantity--;
      } else {
        _cart.splice(pIndex, 1);
      }
    }

    setCart([..._cart]);
    localStorage.setItem(
      process.env.REACT_APP_LS_CART ? process.env.REACT_APP_LS_CART : "cart",
      JSON.stringify(_cart)
    );
  };

  const deleteProductFromCart = (_product_id: string) => {
    // delete the entire product from cart regardless its quantity
    const _cart = [...cart];
    const pIndex = _cart.findIndex((item) => item.product_id === _product_id);
    if (pIndex >= 0) {
      _cart.splice(pIndex, 1);
    }

    setCart([..._cart]);
    localStorage.setItem(
      process.env.REACT_APP_LS_CART ? process.env.REACT_APP_LS_CART : "cart",
      JSON.stringify(_cart)
    );
  };

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem(
      process.env.REACT_APP_LS_CART ? process.env.REACT_APP_LS_CART : "cart"
    );
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        cartCostTotal,

        setCartCostTotal,
        addToCart,
        removeFromCart,
        deleteProductFromCart,
        clearCart,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};

export default CartProvider;
