import React from "react";

export default function ProjectPartners() {
  return (
    <div className="text-center" style={{ borderRight: "1px solid lightgray" }}>
      <h1>Project Partners</h1>
      <hr></hr>
      <a
        href="https://www.uts.edu.au/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={"/img/logo_uts.png"}
          alt="UTS logo"
          style={{ width: "8rem" }}
        />
        <h5>University of Technology Sydney</h5>
      </a>
      <hr></hr>
      <a
        href="https://www.sydneyfishmarket.com.au/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={"/img/logo_sfm.png"}
          alt="SFM logo"
          style={{ width: "8rem" }}
        />
        <h5>Sydney Fish Market</h5>
      </a>
      <hr></hr>
      <a
        href="https://www.foodagility.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={"/img/logo_facrc.png"}
          alt="FACRC logo"
          style={{ width: "8rem" }}
        />
        <h5>Food Agility CRC</h5>
      </a>
      {/* <hr></hr>
      <a
        href='https://www.ucot.world/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img
          src={'/img/logo_ucot.png'}
          alt='UCOT logo'
          style={{ width: '8rem' }}
        />
      </a> */}
    </div>
  );
}
