import { createContext } from "react";
import CartInterface from "../components/Cart/CartInterface";

type CartCtxType = {
  cart: CartInterface[];
  cartCostTotal: number;

  setCartCostTotal: (costTotal: number) => void;
  addToCart: (product_id: string) => void;
  removeFromCart: (product_id: string) => void;
  deleteProductFromCart: (product_id: string) => void;
  clearCart: () => void;
};

const CartContext = createContext({} as CartCtxType);

export default CartContext;
