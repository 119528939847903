import React from "react";

import MapGeocoder from "../Map/MapGeocoder";
import { E_NoseInterface } from "./SFMBoxInterface";

type props = {
  enose: E_NoseInterface[];
};

export default function SFMBoxDetailsEnose({ enose }: props) {
  return (
    <div>
      <h3>E-nose Records</h3>
      {enose.map((item, index) => {
        return (
          <div key={index}>
            <ul style={{ listStyleType: "none" }}>
              <li>Operator: {item.operator}</li>
              <li>Freshness score: {item.noseScore}</li>
              <li>Confidence: {item.confidence}</li>
              <li>
                Date, Time: {item.time.split(" ")[0]},{" "}
                {item.time.split(" ")[1].substr(0, 5)}
              </li>
              <li>
                Address:{" "}
                <MapGeocoder lat={item.latitude} lng={item.longitude} />
              </li>
            </ul>
            <div className="row">
              {item.resultPerTest.map((item, index) => {
                return (
                  <div className="col-md-2" key={index}>
                    <ul>
                      <li>e-nose score: {item.noseScore} </li>
                      <li>confidence: {item.confidence}</li>
                      <li>
                        sensing data: <a href={item.sensingData}>data file</a>
                      </li>
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
