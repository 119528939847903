import React, { useState, useContext } from "react";
import axios from "axios";

import authContext from "../../context/authContext";
import { FaSave } from "react-icons/fa";
import CategoryInterface from "./CategoryInterface";

export default function CategoryAdd() {
  const { token } = useContext(authContext);
  const [newCategory, setNewCategory] = useState<CategoryInterface>({});

  const [imgFile, setImgFile] = useState<File | null>(null);
  const handleSelectedImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setImgFile(event.target.files[0]);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewCategory((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setNewCategory((prevEx) => {
      return {
        ...prevEx,
        [name]: checked,
      };
    });
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const uploadCat = new FormData();
    if (imgFile) {
      uploadCat.append("catImg", imgFile);
    }
    Object.keys(newCategory).forEach(function (key) {
      uploadCat.append(
        key,
        newCategory[key as keyof CategoryInterface] as string
      );
    });

    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/category",
        uploadCat,
        {
          headers: { "x-access-token": token },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    setNewCategory({});
    window.location.reload();
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row mt-2 bg-white">
        <div className="col-1">
          <input
            className="form-control"
            required
            type="text"
            name="name"
            onChange={handleChange}
            placeholder="name"
          />
        </div>
        <div className="col-1">
          <input
            className="form-control"
            required
            type="text"
            name="nameCN"
            onChange={handleChange}
            placeholder="名称"
          />
        </div>

        <div className="col-1">
          <input
            className="form-control"
            required
            type="text"
            name="shelfPos"
            onChange={handleChange}
            placeholder="shelfPos"
          />
        </div>

        <div className="col-2">
          <input
            className="form-control"
            required
            type="file"
            name="image"
            onChange={handleSelectedImage}
          />
        </div>

        <div className="col-5">
          <input
            className="form-control"
            type="text"
            name="info"
            onChange={handleChange}
            placeholder="info"
          />
        </div>
        <div className="col-1">
          <input
            className="form-check-input"
            type="checkbox"
            name="active"
            defaultChecked
            onChange={handleCheck}
          />
        </div>

        <div className="col-1">
          <button className="btn btn-link" type="submit">
            +<FaSave />
          </button>
        </div>
      </div>
    </form>
  );
}
