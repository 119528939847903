import React, { useContext } from 'react';

import ProjectPartners from './ProjectPartners';
import authContext from '../../context/authContext';

export default function AboutUs() {
  const { langEn } = useContext(authContext);

  return (
    <>
      <div className='row m-0'>
        <div className='col-md-2 text-center'>
          <ProjectPartners />
        </div>
        <div className='col-md-10'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                {langEn ? <h1>Technologies</h1> : <h1>科技</h1>}
                <p>
                  Working with Sydney Fish Market, UTS research team in Global Big Data Technologies Centre developed a
                  Blockchain enabled Fish provenance And Quality Tracking
                  (BeFAQT) System to overcome the fish supply chain challenges.
                  BeFAQT combines IoT, Image Processing, E-nose, Blockchain
                  system and mobile Apps in an original and innovative way to
                  address issues in the fish supply chain.
                </p>
                <p>
                  The developed Blockchain, IoT, and AI technologies are being
                  trialled within Sydney Fish Market supply chains. Initial
                  results show that BeFAQT system delivered authentic fish catch
                  provenance and trusted supply chain tracking. It also provided
                  a comprehensive fish freshness index by integrating
                  measurements from IoT, sensors, E-eye, and E-nose. These
                  provenance, tracking, and freshness data are secured by
                  Blockchain, fed into Sydney Fish Market Online Trading
                  platform, and shared with traders and consumers.
                </p>
              </div>
              <div className='col-md-12'>
                <h3 className='mt-3'>
                  Blockchain enabled Online Trading Platform:
                </h3>
              </div>
              <div className='col-md-6'>
                <img src='/img/pic_befaqt.png' alt='freshness' width='100%' />
              </div>
              <div className='col-md-6'>
                <p>
                  The UTS team has developed a consortium Blockchain, run by
                  Sydney Fish Market and UTS, to provide tamper-resistant data
                  sharing service. Smart contracts are developed to certify,
                  store and index the fish provenance, quality tracking, and
                  freshness data. Application Program Interfaces have been
                  developed to feed the data obtained from App, IoT, E-eye and
                  E-nose through Blockchain and Smart Contracts to Sydney Fish
                  Market Online Trading platform.
                </p>
                <p>
                  Unlike most existing Blockchain developments that focus on
                  cryptocurrencies, here Blockchain innovations aim to secure
                  trusted sharing of data (via blockchain platform) and actions
                  (via smart contracts) over a wide range of technologies,
                  including IoT, E-eye, E-nose, and Fisherman App. The collected
                  provenance and tracking data are securely transferred to
                  Sydney Fish Market Online Trading Platform in real-time to
                  enable “live” auctions as the catch happens on the fishing
                  boat.
                </p>
              </div>
              <div className='col-md-12'>
                <h3 className='mt-3'>IoT: </h3>
              </div>
              <div className='col-md-6'>
                <p>
                  the UTS team has developed new 5G / NB-IoT devices that are
                  mounted on Sydney Fish Market fish boxes, which are used by
                  fishermen across NSW to store and transport fish produce. The
                  instrumented fish boxes record the locations and temperature
                  conditions onboard the fishing boats at the origin and across
                  the fish supply chain.
                </p>
                <p>
                  The developed IoT + Blockchain technologies achieve much more
                  than the existing data logger technology in terms of
                  information reliability, trust data sharing, supply chain
                  coverage, and real-time data delivery, which enable prompt
                  actions. Utilising 5G NB-IoT technology, these devices can
                  achieve seamless connectivity for domestic and international
                  supply chain tracking. Smart buffering algorithms are designed
                  to cope with the limited mobile coverage in the ocean and in
                  cool rooms. Energy efficient firmware is implemented to
                  prolong IoT device lifetime, permitting the tracking of the
                  fish boxes from catch origin to Sydney Fish Market.
                </p>
                <p>
                  In this way, the temperature and location of every individual
                  Sydney Fish Market box can be obtained in real-time and then
                  secured in Blockchain by Smart Contract.
                </p>
              </div>
              <div className='col-md-6'>
                <img
                  src='/img/pic_iot_sfmbox.png'
                  alt='iot_sfmbox'
                  width='100%'
                />
              </div>
              <div className='col-md-12'>
                <h3 className='mt-3'>E-eye: </h3>
              </div>
              <div className='col-md-6'>
                <img src='/img/pic_imgproc.png' alt='img proc' width='100%' />
              </div>
              <div className='col-md-6'>
                <p>
                  The UTS team has built a robust computer vision platform to
                  automatically and unbiasedly extract essential pixel-based
                  elements known as “image features” of objects in the
                  image/video. Advanced machine learning is then applied to
                  build AI models for fish freshness assessment, size estimation
                  and species recognition.
                </p>
                <p>
                  This is the first time multi-sensor (IoT sensors, E-eye, and
                  E-nose) technologies have been combined to address fish
                  freshness assessment in an industrial environment. Novel
                  designs and Artificial Intelligence (AI) methodologies are
                  created in each of the technologies to address practical
                  conditions in the fish supply chains.{' '}
                </p>
              </div>
              <div className='col-md-12'>
                <h3 className='mt-3'>E-nose: </h3>
              </div>
              <div className='col-md-6'>
                <p>
                  The UTS team has also developed machine olfactory technologies
                  to assess fish freshness. The e-nose system includes an
                  airflow intake system and control logics to carry out the
                  automatic odour analysis. Novel non-parametric kernel based
                  modelling and Hidden Markov Model are developed for data
                  processing and classification.
                </p>
              </div>
              <div className='col-md-6'>
                <img src='/img/pic_enose.png' alt='enose' width='100%' />
              </div>
              <div className='col-md-12'>
                <h3 className='mt-3'>Fisherman App: </h3>
              </div>
              <div className='col-md-6'>
                <img
                  src='/img/pic_fisherapp.png'
                  alt='fisherapp'
                  width='100%'
                />
              </div>
              <div className='col-md-6'>
                <p>
                  The Blockchain-secured Fisherman App records and certifies
                  fish catch time, location, photo, and the company. The fish
                  species recognition and size estimation developed in the E-eye
                  system is being integrated in the App to achieve automated
                  catch reporting. Traders and consumers can also use the
                  Blockchain App to verify fish provenance and quality tracking
                  information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
