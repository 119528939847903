import React, { useState, useContext } from "react";
import axios from "axios";
import { FaSave } from "react-icons/fa";

import authContext from "../../context/authContext";
import ProductInterface from "../Product/ProductInterface";
import CategoryInterface from "../Category/CategoryInterface";
// import productContext from '../../context/productContext';

type props = {
  index: number;
  product: ProductInterface;
  categories: CategoryInterface[];
};

export default function ProductManageEdit({
  index,
  product,
  categories,
}: props) {
  const { token } = useContext(authContext);
  const [editProduct, setEditProduct] = useState(product);

  const [imgFiles, setImgFiles] = useState<File[]>([]);
  const handleSelectedImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileArray = [];
    if (event.target.files) {
      for (let i = 0; i < event.target.files.length; i++) {
        fileArray.push(event.target.files[i]);
      }
    }
    setImgFiles(fileArray);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditProduct((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setEditProduct((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setEditProduct((prevEx) => {
      return {
        ...prevEx,
        [name]: checked,
      };
    });
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const uploadProduct = new FormData();
    if (imgFiles.length > 0) {
      // if new images are loaded, insert the image files to FormData
      // field name 'productImage' must match backend post: upload.single('productImage')
      imgFiles.map((item) => {
        return uploadProduct.append("productImgs", item);
      });
    }

    Object.keys(editProduct).forEach(function (k) {
      uploadProduct.append(
        k,
        editProduct[k as keyof ProductInterface] as string
      );
    });

    try {
      await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/product/" + product._id,
        uploadProduct,
        {
          headers: { "x-access-token": token },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload();
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={index % 2 ? "row mt-2 bg-white" : "row mt-2"}>
        <div className="col-1">{editProduct.name}</div>
        <div className="col-1">{editProduct.nameCN}</div>
        <div className="col-1">
          <input
            className="form-control"
            type="text"
            name="brand"
            value={editProduct.brand}
            onChange={handleChange}
            placeholder="Brand"
          />
        </div>
        <div className="col-1">
          <select
            className="form-select"
            name="category_id"
            onChange={handleSelect}
          >
            <option disabled value="">
              select category
            </option>
            {categories.map((item) => {
              return (
                <option
                  key={item.name}
                  value={item._id}
                  selected={item._id === editProduct.category_id}
                >
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="col-1 ">
          <input
            className="form-control"
            type="file"
            name="images"
            multiple
            onChange={handleSelectedImage}
          />
        </div>
        <div className="col-1 text-right">
          <span className="d-lg-none">price: </span>
          <input
            className="form-control text-end"
            required
            type="number"
            name="price"
            value={editProduct.price}
            onChange={handleChange}
          />
        </div>
        <div className="col-1 text-center">
          <input
            className="form-control"
            name="unit"
            type="text"
            value={editProduct.unit}
            onChange={handleChange}
          />
        </div>

        <div className="col-1 text-center">
          <input
            className="form-check-input"
            type="checkbox"
            checked={editProduct.includeGST}
            disabled
          />
        </div>

        <div className="col-1 text-right">
          <input
            className="form-control text-end"
            required
            type="text"
            name="stock"
            value={editProduct.stock}
            onChange={handleChange}
          />
        </div>
        <div className="col-1 text-center">
          <input
            className="form-check-input"
            type="checkbox"
            name="onShelf"
            checked={editProduct.onShelf}
            onChange={handleCheck}
          />
        </div>
        <div className="col-1">
          <input
            className="form-control"
            required
            name="shelfPos"
            type="text"
            value={editProduct.shelfPos}
            onChange={handleChange}
          />
        </div>
        <div className="col-1">
          <button className="btn btn-link" type="submit">
            <FaSave />
          </button>
        </div>
      </div>

      <div className={index % 2 ? "row bg-white" : "row"}>
        <div className="col-11">
          <input
            className="form-control"
            name="info"
            type="text"
            value={editProduct.info}
            onChange={handleChange}
            placeholder="Product Information"
          />
        </div>
      </div>
    </form>
  );
}
