import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import authContext from '../context/authContext';

export default function Header() {
  const { langEn } = useContext(authContext);
  return (
    <div className='row p-1' style={{ width: '100%' }}>
      <div className='col-md-2 text-center'>
        <Link to='/'>
          <img
            src={'/logo_BeFAQT.png'}
            alt='BeFAQT logo'
            style={{ width: '8rem' }}
          />
        </Link>
      </div>
      <div className='col-md-8'>
        {langEn ? (
          <h1>
            BeFAQT:{' '}
            <small>
              <u>B</u>lockchain <u>e</u>nabled <u>F</u>ish provenance <u>A</u>nd{' '}
              <u>Q</u>uality <u>T</u>racking
            </small>
          </h1>
        ) : (
          <h1>区块链保障的海鲜追踪溯源</h1>
        )}

        {/* <p>
          BeFAQT integrates multi-sensing technologies, including IoT, sensing,
          E-eye and E-nose, on a Blockchain data sharing platform and mobile
          application to verify fish catch origin, track supply-chain in
          real-time, and automate fish freshness assessment.
        </p> */}
        <h5>
          *** Congratulations! BeFAQT was the{' '}
          <a
            href='https://aiia.com.au/iaward/2020-nsw-iawards-winners/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Winner of 2020 NSW iAwards
          </a>{' '}
          ***
        </h5>
      </div>
      <div className='col-md-2'>
        <a href='/about/us' target='_blank' rel='noopener noreferrer'>
          <img
            src={'/img/pic_BeFAQT-iAwards.png'}
            alt='BeFAQT logo'
            style={{ width: '100%' }}
          />
        </a>
      </div>
    </div>
  );
}
