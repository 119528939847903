import React, { useContext } from "react";

import ProjectPartners from "./ProjectPartners";
import authContext from "../../context/authContext";
import vid from "./BeFAQTvideoABCnews.mp4";

const bgVideoStyle = {
  // height: '100%',
  width: "100%",
  // float: 'left',
  // top: '0',
  padding: "none",
  // position: 'fixed' /* optional depending on what you want to do in your app */,
};

export default function AboutUs() {
  const { langEn } = useContext(authContext);

  return (
    <>
      <div className="row m-0">
        <div className="col-lg-2 text-center">
          <ProjectPartners />
        </div>
        <div className="col-lg-10">
          <div className="container-fluid">
            {langEn ? <h1>Project</h1> : <h1>项目</h1>}
            <div className="row">
              <div className="col-12">
                <h3>Background and Objectives</h3>
              </div>
              <div className="col-lg-6">
                {/* <video style={bgVideoStyle} autoPlay loop muted> */}
                <video style={bgVideoStyle} autoPlay playsInline controls>
                  <source src={vid} type="video/mp4" />
                </video>
              </div>
              <div className="col-lg-6">
                <p>
                  Seafood auctions start from 5:30am every weekday at Sydney
                  Fish Market, where buyers inspect the produce before their
                  bidding. Sydney Fish Market had been planning to develop an
                  online version of its famous seafood auction. The challenge
                  then was how to provide the same onsite inspection experience
                  (see, feel, and smell) to the online buyers. In 2018, the Food
                  Agility CRC organised two workshops attended by
                  representatives from Sydney Fish Market, UTS research teams,
                  Fisheries Research and Development Corporation (FRDC),
                  Fishermen’s Co-operatives, NSW Department of Primary
                  Industries (DPI), and WWF. The workshops identified a number
                  of challenges, including the lack of fish origin and quality
                  information within fish supply chains, as important
                  constraints to innovation and growth in the seafood industry.
                </p>
              </div>
              <div className="col-12">
                <h3>Technologies and Trials</h3>
              </div>
              <div className="col-md-6">
                <p>
                  The UTS research team proposed to develop a Blockchain enabled
                  Fish provenance And Quality Tracking (BeFAQT) system to
                  overcome the fish supply chain challenges. In this project,
                  the BeFAQT team developed a wide range of technologies,
                  including Internet of Things (IoT), e-eye, e-nose, blockchain
                  enabled mobile App and online platform. The developed
                  technologies have been trialled within the fishing industry.
                  In particular, the IoT, Blockchain platform and App
                  technologies have been trialled with fishermen to secure fish
                  catch origin and supply chain tracking. The e-eye and e-nose
                  technologies have been trialled for freshness assessments in
                  Sydney Fish Market, where thousands of fish samples are
                  collected and analysed. The BeFAQT system is being integrated
                  in Sydney Fish Market’s online trading platform to deliver
                  blockchain secured provenance and quality tracking data to the
                  buyers.
                </p>
              </div>
              <div className="col-md-6">
                <img
                  src="/img/pic_visit_fisherman.png"
                  alt="with fisherman"
                  width="100%"
                />
              </div>
              <div className="col-12">
                <h3>Benefits and Impacts</h3>
              </div>
              <div className="col-md-6">
                <img
                  src="/img/pic_fishrecord.png"
                  alt="with fisherman"
                  width="100%"
                />
              </div>
              <div className="col-md-6">
                <p>
                  The BeFAQT system enables the online buyers to achieve better
                  than onsite inspection experiences, in that they can not only
                  have the see, feel and smell experience, but also have access
                  to comprehensive information in seafood provenance, supply
                  chain tracking, and quality assessment. With BeFAQT system,
                  online buyers can have early access to catch list, including
                  authenticated fish photo, species, and sizes, with trusted
                  origin, visible condition tracking, and objective quality
                  assessment. The developed BeFAQT technologies support Sydney
                  Fish Market online trading platform to achieve simplified
                  process, transparent and trusted supply chain, shorter time to
                  market and wider market access.
                </p>
                <p>
                  BeFAQT provides a provable way for the fishers to demonstrate
                  provenance such as location of catch and proof of cold chain
                  so that better quality product can be rewarded with higher
                  prices. The technologies can potentially reduce paperwork with
                  automated catch recording of species and sizes to the Sydney
                  Fish Market online platform. With BeFAQT system, the catch
                  provenance is shared immediately in the online trading
                  platform, where the buyers can start bidding on a fish, e.g.
                  Kingfish, whilst the fisherman is still at sea.
                </p>
                <p>
                  According to Bryan Skepper, Chair of NSW Seafood Industry
                  Council, “the BeFAQT system has huge potential. Once
                  successfully commercialised, the developed technologies can be
                  extended throughout the fishing industry in Australia, and
                  potentially exported to other countries, benefiting the entire
                  fishing industry. The developed technologies can be extended
                  to other food supply chains, such as meat, fruit and
                  vegetables.”
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
