import React, { useContext } from "react";
import { Link } from "react-router-dom";

import authContext from "../../context/authContext";
import CategoryInterface from "../Category/CategoryInterface";
import "./Product.css";

type ProductCatItemProps = {
  key?: number;
  cat: CategoryInterface;
};

export default function ProductCatItem({ cat }: ProductCatItemProps) {
  const { langEn } = useContext(authContext);

  const { name, nameCN, info, image } = cat;

  return (
    <div className="col-12 col-md-6 col-lg-4 d-flex align-items-stretch p-2">
      <div className="card">
        <div className="img-container p-3">
          <Link to={`/productsbycat/${name!.replace(/\s/g, "")}`}>
            <img
              src={process.env.REACT_APP_ASSET_URL + "/" + image}
              alt={name}
              className="card-catimg-top"
            />
          </Link>
        </div>
        <div className="card-footer">
          <h3 className="text-center mb-0">{langEn ? name : nameCN}</h3>
          <p>{info}</p>
        </div>
      </div>
    </div>
  );
}
