import React, { useContext } from "react";
import { useParams, Link } from "react-router-dom";

import productContext from "../../context/productContext";
import authContext from "../../context/authContext";
import ProductDetailsImages from "./ProductDetailsImages";
import CartContext from "../../context/CartContext";

function ProductDetails() {
  const { productId } = useParams();
  const { products } = useContext(productContext);
  const { addToCart } = useContext(CartContext);
  const { langEn } = useContext(authContext);

  const product = products.find((item) => item._id === productId);
  if (!product) return <></>; // skip the first round of render, where products are not yet loaded.

  const {
    name,
    nameCN,
    images,
    brand,
    unit,
    price,
    includeGST,
    info,
    shelfPos,
  } = product;

  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-10 mx-auto text-center text-slanted text-blue my-5">
          <h1>{langEn ? name : nameCN}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-10 mx-auto col-md-6 my-3">
          <ProductDetailsImages productImages={images!} />
        </div>
        <div className="col-10 mx-auto col-md-6 my-3">
          <h2>
            {langEn ? "Name" : "名称"}: {langEn ? name : nameCN}
          </h2>
          <h4 className="text-title text-uppercase text-muted mt-3 mb-2">
            {langEn ? "Brand" : "品牌"}: {brand}
          </h4>
          <h5 className="text-blue">
            Price: ${price} per {unit}
            {includeGST && ",  include GST $" + (price! / 11).toFixed(2)}
          </h5>
          <p className="text-muted lead">
            Shelf Position: aisle-{shelfPos!.split(".")[0]}.shelf-
            {shelfPos!.split(".")[1]}.level-{shelfPos!.split(".")[2]}
          </p>
          <p className="text-capitalize font-weight-bold mt-3 mb-0">{info}</p>
          {/* buttons */}
          <div>
            <Link to="/products">
              <button
                className="btn btn-outline-primary px-2 py-1 mx-2"
                style={{ fontSize: "1.2rem", borderWidth: "medium" }}
              >
                Back to Shop <i className="fas fa-store"></i>
              </button>
            </Link>

            <button
              className="btn btn-outline-success px-2 py-1"
              style={{ fontSize: "1.2rem", borderWidth: "medium" }}
              onClick={() => addToCart(productId!)}
            >
              Add to Cart<i className="fas fa-cart-plus"></i>
            </button>
            <Link to="/cart">
              <button
                className="btn btn-outline-success px-2 py-1 mx-2"
                style={{ fontSize: "1.2rem", borderWidth: "medium" }}
              >
                Go to Cart<i className="fas fa-shopping-cart"></i>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
