import React, { useState, useContext } from "react";
import axios from "axios";
import { FaSave } from "react-icons/fa";

import authContext from "../../context/authContext";
import productContext from "../../context/productContext";
import ProductInterface from "../Product/ProductInterface";

function ProductManageAdd() {
  const { token } = useContext(authContext);
  const { catList } = useContext(productContext);
  const [newProduct, setNewProduct] = useState({} as ProductInterface);

  const [imgFiles, setImgFiles] = useState<File[]>([]);
  const handleSelectedImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    let fileArray = [];
    if (event.target.files) {
      for (let i = 0; i < event.target.files.length; i++) {
        fileArray.push(event.target.files[i]);
      }
    }
    setImgFiles([...fileArray]);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewProduct((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setNewProduct((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setNewProduct((prevEx) => {
      return {
        ...prevEx,
        [name]: checked,
      };
    });
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const uploadProduct = new FormData();
    imgFiles.forEach((item) => {
      uploadProduct.append("productImgs", item);
    });
    // field name 'productImage' must match backend post: upload.single('productImage')

    Object.keys(newProduct).forEach(function (k) {
      uploadProduct.append(
        k,
        newProduct[k as keyof ProductInterface] as string
      );
    });

    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + "/product",
        uploadProduct,
        {
          headers: { "x-access-token": token },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    setNewProduct({});
    setImgFiles([]);
    window.location.reload();
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row bg-white mt-2">
        <div className="col-1">
          <input
            required
            className="form-control"
            type="text"
            name="name"
            onChange={handleChange}
            placeholder="name"
          />
        </div>
        <div className="col-1">
          <input
            required
            className="form-control"
            type="text"
            name="nameCN"
            onChange={handleChange}
            placeholder="名称"
          />
        </div>
        <div className="col-1">
          <input
            className="form-control"
            name="brand"
            type="text"
            onChange={handleChange}
            placeholder="Brand:"
          />
        </div>

        <div className="col-1">
          <select
            className="form-select"
            required
            name="category_id"
            defaultValue=""
            onChange={handleSelect}
          >
            <option disabled value="">
              select category
            </option>
            {catList.map((item) => {
              return (
                <option key={item.name} value={item._id}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="col-1 ">
          <input
            className="form-control"
            required
            type="file"
            name="img"
            multiple
            onChange={handleSelectedImage}
          />
        </div>
        <div className="col-1">
          <input
            className="form-control text-end"
            required
            type="number"
            step="any"
            min={0}
            name="price"
            onChange={handleChange}
            placeholder="price"
          />
        </div>
        <div className="col-1">
          <input
            className="form-control"
            name="unit"
            type="text"
            onChange={handleChange}
            placeholder="unit"
          />
        </div>

        <div className="col-1 text-center">
          <input
            className="form-check-input"
            type="checkbox"
            name="includeGST"
            onChange={handleCheck}
          />
        </div>

        <div className="col-1">
          <input
            className="form-control text-end"
            required
            type="number"
            name="stock"
            onChange={handleChange}
            placeholder="stock"
          />
        </div>
        <div className="col-1 text-center">
          <input
            className="form-check-input"
            type="checkbox"
            name="onShelf"
            defaultChecked
            onChange={handleCheck}
          />
        </div>
        <div className="col-1">
          <input
            className="form-control"
            required
            name="shelfPos"
            type="text"
            onChange={handleChange}
            placeholder="aisle.shelf.level"
          />
        </div>
        <div className="col-1">
          <button className="btn btn-link" type="submit">
            <FaSave />
            <span>+</span>
          </button>
        </div>
      </div>

      <div className="row bg-white">
        <div className="col-11">
          <input
            className="form-control"
            name="info"
            type="text"
            onChange={handleChange}
            placeholder="Product Info"
          />
        </div>
      </div>
    </form>
  );
}
export default ProductManageAdd;
