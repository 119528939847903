import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import OrderCheckoutForm from "./OrderCheckoutForm";
// Learning
// To best leverage Stripe’s advanced fraud functionality,
// include this script on every page, not just the checkout page.
// This allows Stripe to detect anomalous behavior that may be indicative
// of fraud as customers browse your website.
// Note: This is why we are adding it to a Layout component.

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "STRIPE KEY not exist"
);

// TIP
// call loadStripe outside of a component
// in that way there's no chance it will get
// called more times than it needs to

export default function CartCheckout() {
  return (
    <Elements stripe={stripePromise}>
      <OrderCheckoutForm />
    </Elements>
  );
}
