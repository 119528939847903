import React, { useContext } from "react";
import { Link } from "react-router-dom";

import ProjectPartners from "../About/ProjectPartners";
import ProductCatItem from "./ProductCatItem";
import "./Product.css";
import catchOfTheDay from "./befaqt-catch.mp4";
import productContext from "../../context/productContext";
import authContext from "../../context/authContext";

function Products() {
  const { categories } = useContext(productContext);
  const { langEn } = useContext(authContext);

  return (
    <div className="row" style={{ width: "100%" }}>
      <div className="col-lg-2 text-center">
        <ProjectPartners />
      </div>
      <div className="col-lg-10">
        <h1 className="text-center">
          {langEn
            ? "Catch of the Day with Tracking Records"
            : "今日之钓-含追踪记录"}
        </h1>
        <p className="text-center">
          Click on the video to see the provenance and quality tracking records
        </p>
        {categories.length > 0 && (
          // SFMBox: categories[0] is reserved for 'Catch of the Day' category,
          // which hosts SFMBox products
          <Link to={"/SFMBoxes/byId/0345579444"}>
            {/* SFMBox: categories[0] is "Catch" to host SFMBoxes */}
            <div className="text-center">
              {/* <img
                src={'/img/catchoftheday.png'}
                alt='Catch'
                style={{ width: '90%' }}
              /> */}
              <video width="90%" autoPlay muted playsInline>
                <source src={catchOfTheDay} type="video/mp4" />
                {/* <source src="movie.ogg" type="video/ogg"> */}
                Your browser does not support the video tag.
              </video>
            </div>
          </Link>
        )}

        <hr></hr>
        {langEn ? (
          <h1 className="text-center">Seafood Categories</h1>
        ) : (
          <h3 className="text-center">产品分类</h3>
        )}
        <div className="row">
          {categories.map((cat, index) => {
            return (
              index > 0 && (
                // SFMBox: categories[0] is displayed above, not here
                <ProductCatItem key={index} cat={cat} />
              )
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Products;
