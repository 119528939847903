import React from "react";
import { CatchProvInterface } from "./SFMBoxInterface";

type props = {
  catchProv: CatchProvInterface;
};

export default function SFMBoxDetailsProv({ catchProv }: props) {
  return (
    <div>
      <h5>Catch Provenance</h5>
      {Object.keys(catchProv).length > 0 && (
        <>
          <div>Species: {catchProv.species}</div>
          <div>Size: {catchProv.size}</div>
          <div>
            Date, Time: {catchProv.time.split(" ")[0].substr(0, 15)},{" "}
            {catchProv.time.split(" ")[1].substr(0, 5)}
          </div>
          {/* <div>Boat: {catchProv.boat}</div> */}
          <div>
            Location Code: {catchProv.locationCodeLat} /{" "}
            {catchProv.locationCodeLong}
          </div>
          <div>Method: {catchProv.method} fishing</div>
        </>
      )}
    </div>
  );
}
