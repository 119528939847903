import React, { useState, useContext } from "react";
import axios from "axios";
import { FaSave } from "react-icons/fa";

import authContext from "../../context/authContext";
import UserInterface, { UserUpdateInterface } from "../User/UserInterface";

type props = {
  index: number;
  user: UserInterface;
};

export default function UserEdit({ index, user }: props) {
  const { token } = useContext(authContext);
  const [editUser, setEditUser] = useState<UserUpdateInterface>({
    phone: user.phone,
    role: user.role,
    status: user.status,
    address: user.address,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditUser((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setEditUser((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleAddressInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setEditUser((prevState) => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      };
    });
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    try {
      await axios.put(
        process.env.REACT_APP_BACKEND_URL + "/user/" + user._id,
        editUser,
        {
          headers: { "x-access-token": token },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload();
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={index % 2 ? "row mt-1" : "row mt-1 bg-white"}>
        <div className="col-2">{user.fname + " " + user.lname}</div>
        <div className="col-2">{user.email}</div>
        <div className="col-1">
          <select
            className="form-select"
            name="role"
            onChange={handleSelect}
            defaultValue={user.role}
          >
            <option value="user">user</option>
            <option value="admin">admin</option>
            <option value="guest">guest</option>
          </select>
        </div>

        <div className="col-2">
          <input
            className="form-control"
            type="text"
            name="phone"
            value={editUser.phone}
            onChange={handleChange}
          />
        </div>
        <div className="col-3">
          <div className="row">
            <div className="col-12">
              <input
                className="form-control"
                type="text"
                name="line1"
                value={editUser.address && editUser.address.line1}
                onChange={handleAddressInput}
              />
            </div>
            <div className="col-6">
              <input
                className="form-control"
                type="text"
                name="suburb"
                value={editUser.address && editUser.address.suburb}
                onChange={handleAddressInput}
              />
            </div>
            <div className="col-3">
              <input
                className="form-control"
                type="text"
                name="state"
                value={editUser.address && editUser.address.state}
                onChange={handleAddressInput}
              />
            </div>
            <div className="col-3">
              <input
                className="form-control"
                type="text"
                name="postcode"
                value={editUser.address && editUser.address.postcode}
                onChange={handleAddressInput}
              />
            </div>
          </div>
        </div>
        <div className="col-1">
          <select
            className="form-select"
            name="status"
            onChange={handleSelect}
            defaultValue={user.status}
          >
            <option value="active">active</option>
            <option value="inactive">inactive</option>
            <option value="disabled">disabled</option>
          </select>
        </div>

        <div className="col-1">
          <button className="btn btn-link" type="submit">
            <FaSave />
          </button>
        </div>
      </div>
    </form>
  );
}
