import React, { useState, useEffect } from "react";
import Geocode from "react-geocode";

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY!);

// set response language. Defaults to english.
Geocode.setLanguage("en");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("au");

// Enable or disable logs. Its optional.
// Geocode.enableDebug();

type props = {
  lat: number;
  lng: number;
};

export default function MapGeocoder({ lat, lng }: props) {
  const [streetAddr, setStreetAddr] = useState("");

  useEffect(() => {
    Geocode.fromLatLng(lat.toString(), lng.toString()).then(
      (response: any) => {
        const address = response.results[0].formatted_address;
        setStreetAddr(address);
      },
      (error: any) => {
        console.error(error);
      }
    );
  }, [lat, lng]);
  // Get address from latidude & longitude.

  // Get latidude & longitude from address.
  //   Geocode.fromAddress('Eiffel Tower').then(
  //     (response) => {
  //       const { lat, lng } = response.results[0].geometry.location;
  //       console.log(lat, lng);
  //     },
  //     (error) => {
  //       console.error(error);
  //     }
  //   );

  return <span>{streetAddr}</span>;
}
