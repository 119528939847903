import { createContext } from "react";

interface authCtxInterface {
  langEn: boolean;
  setLang: () => void;
  token: string | null;
  user_id: string | null;
  user_fname: string;
  admin: boolean;
  login: (
    _token: string,
    _uId: string,
    _uName: string,
    _role: string,
    _expDate?: Date
  ) => void;
  logout: () => void;
}

const authContext = createContext({} as authCtxInterface);

export default authContext;
