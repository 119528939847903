import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
} from "recharts";

import MapGeocoder from "../Map/MapGeocoder";
import { IoTDataInterface, IoTTrackingIntrface } from "./SFMBoxInterface";

type props = {
  iotResult: IoTDataInterface;
};

type FigDataType = {
  time: string;
  temperature: number;
};

export default function SFMBoxDetailsIoT({ iotResult }: props) {
  const [figData, setFigData] = useState<FigDataType[]>([]);
  const [listData, setListData] = useState<IoTTrackingIntrface[]>([]);

  useEffect(() => {
    let _data: FigDataType[] = [];
    let _list: IoTTrackingIntrface[] = [];
    let latPrev = 0,
      lngPrev = 0;
    iotResult.iotTracking.forEach((item) => {
      let figPoint = {
        time: item.time.split(" ")[1].substring(0, 5),
        temperature: Math.round(item.temperature * 100) / 100,
      };
      _data.push(figPoint);

      const { iotID, time, latitude, longitude, temperature, txHash } = item;
      let latRev: number, lngRev: number;
      if (longitude === 0) {
        // if no lat/lng data, reuse previous position
        latRev = latPrev;
        lngRev = lngPrev;
      } else {
        latRev = latitude;
        lngRev = longitude;
        latPrev = latitude;
        lngPrev = longitude;
      }
      let listItem: IoTTrackingIntrface = {
        time,
        latitude: latRev,
        longitude: lngRev,
        iotID,
        temperature,
        txHash,
      };
      _list.push(listItem);
    });
    setFigData(_data);
    setListData(_list);
  }, [iotResult]);

  return (
    <div>
      <h3>
        IoT Tracking with{" "}
        <span>
          IoT ID: {iotResult.iotTracking && iotResult.iotTracking[0].iotID}
        </span>
      </h3>
      <ul style={{ listStyleType: "none" }}>
        <li>Operator: {iotResult.operator}</li>
        <li>Freshness score: {iotResult.iotScore}</li>
        <li>Confidence: {iotResult.confidence} </li>
        <li>
          Date, Time: {iotResult.time.split(" ")[0]},{" "}
          {iotResult.time.split(" ")[1].substring(0, 5)}
        </li>
        <li>
          Address:{" "}
          <MapGeocoder lat={iotResult.latitude} lng={iotResult.longitude} />
        </li>
      </ul>
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <div className="col-5">Date | Time</div>
            <div className="col-5">Location: latitude / longitude</div>
            <div className="col-2 text-right">Temperature</div>
          </div>
          {listData.map((item, index) => {
            return (
              <div key={index}>
                <div className="row">
                  <div className="col-5">
                    {item.time.split(" ")[0]} |{" "}
                    {item.time.split(" ")[1].substring(0, 5)}
                  </div>

                  <div className="col-5">
                    <span>
                      {item.latitude} / {item.longitude}
                    </span>
                  </div>
                  <div className="col-2 text-right">
                    {item.temperature.toFixed(2)}&#8451;
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="col-md-6">
          <BarChart
            // layout='vertical'
            width={600}
            height={350}
            data={figData}
            margin={{ top: 20, right: 10, left: 10, bottom: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="time"
              label={{ value: "time", offset: -10, position: "insideBottom" }}
            />
            <YAxis
              dataKey="temperature"
              type="number"
              //   domain={[0, 12]}
              label={{
                value: "temperature",
                angle: -90,
                offset: 20,
                position: "insideLeft",
              }}
            />
            <Tooltip />
            <Legend verticalAlign="top" />
            <Bar
              dataKey="temperature"
              fill="#8884d8"
              label={{ position: "top" }}
            >
              {figData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    entry.temperature <= 3.0
                      ? "#0080ff" // blue
                      : entry.temperature <= 6.0
                      ? "#ffbf00" // orange
                      : "#ff4000" // red
                  }
                />
              ))}
            </Bar>
          </BarChart>
        </div>
      </div>
    </div>
  );
}
