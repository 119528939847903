import React, { useState, useContext } from "react";
import authContext from "../../context/authContext";
import ProductContext from "../../context/productContext";

import CategoryColumns from "./CategoryColumns";
import CategoryItem from "./CategoryItem";
import CategoryAdd from "./CategoryAdd";
import CategoryEdit from "./CategoryEdit";

export default function CategoryManage() {
  const { langEn } = useContext(authContext);
  const { categories } = useContext(ProductContext);

  const [editCategoryId, setEditCategoryId] = useState("");
  const setEdit = (catId: string) => {
    setEditCategoryId(catId);
  };

  return (
    <div className="container-fluid">
      <h1>{langEn ? "Category Management" : "类别管理"}</h1>
      <div className="container-fluid">
        <CategoryColumns />
        <CategoryAdd />
        {categories.map((cat, index) => {
          if (cat._id === editCategoryId) {
            return <CategoryEdit key={cat._id} index={index} cat={cat} />;
          } else {
            return (
              <CategoryItem
                key={cat._id}
                index={index}
                cat={cat}
                setEdit={setEdit}
              />
            );
          }
        })}
      </div>
    </div>
  );
}
