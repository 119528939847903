import React, { useState, useContext, useEffect, useCallback } from "react";
import Collapse from "react-bootstrap/Collapse";

import ProductItem from "./ProductItem";
import "./Product.css";
import productContext from "../../context/productContext";
import authContext from "../../context/authContext";
import ProductInterface from "./ProductInterface";

export default function ProductSearch() {
  const { onShelfProducts } = useContext(productContext);
  const { langEn } = useContext(authContext);

  type BrandSearchType = {
    name: string;
    isChecked: boolean;
  };
  const [filteredProducts, setFilteredProducts] = useState<ProductInterface[]>(
    []
  );
  const [brands, setBrands] = useState<BrandSearchType[]>([]);
  const [openBrands, setOpenBrands] = useState(false);

  useEffect(() => {
    // setFilteredProducts(onShelfProducts);
    setFilteredProducts([]);

    let _brands: BrandSearchType[] = [];
    onShelfProducts.forEach((item) => {
      // find all defined brands without duplicate
      if (
        typeof item.brand !== "undefined" &&
        item.brand !== null &&
        item.brand !== "" &&
        !_brands.some((brd) => brd.name === item.brand)
      ) {
        _brands.push({
          name: item.brand,
          isChecked: false,
        });
      }
    });
    _brands.sort((a, b) => {
      if (a.name.toLowerCase() >= b.name.toLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    });
    setBrands([..._brands]);
  }, [onShelfProducts]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    let currList: ProductInterface[] = [];
    let newList: ProductInterface[] = [];
    if (event.target.value !== "") {
      currList = onShelfProducts;
      newList = currList.filter((item) => {
        const lcName = item.name!.toLowerCase();
        const searchStr = event.target.value.toLowerCase();
        return lcName.includes(searchStr);
      });
    } else {
      // newList = onShelfProducts;
    }
    setFilteredProducts([...newList]);
  };

  const handleCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const index = +event.target.name;

      let _brandsTemp = [...brands];
      _brandsTemp[index].isChecked = event.target.checked;

      setBrands(_brandsTemp);

      let _checkedBrands: string[] = [];
      _brandsTemp.forEach((brand) => {
        if (brand.isChecked) {
          _checkedBrands.push(brand.name);
        }
      });

      let newList: ProductInterface[] = [];
      if (_checkedBrands.length > 0) {
        newList = onShelfProducts.filter((item) => {
          return _checkedBrands.includes(item.brand!);
        });
      } else {
        // newList = [...onShelfProducts];
      }

      setFilteredProducts([...newList]);
    },
    [brands, onShelfProducts]
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-11 col-lg-1">
          <label className="search-label" htmlFor="search-input">
            <input
              type="text"
              id="search-input"
              onChange={handleSearch}
              placeholder={langEn ? "🔍Search..." : "🔍检索..."}
            />
          </label>

          <button
            className="btn btn-link px-1"
            onClick={() => setOpenBrands(!openBrands)}
            aria-controls="brands"
            aria-expanded={openBrands}
          >
            <h5>▼{langEn ? "Brands" : "品牌"}</h5>
          </button>
          <Collapse in={openBrands}>
            <div id="brands">
              {brands.map((item, index) => {
                return (
                  <div className="form-check" key={index}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      key={index}
                      name={index.toString()}
                      checked={item.isChecked}
                      onChange={handleCheck}
                      id="defaultCheck1"
                    />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      {item.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </Collapse>
        </div>
        {filteredProducts.length < 1 && (
          <div className="col-lg-11">
            <h3>
              <span role="img" aria-label="search">
                👈 {"Search Products"}
              </span>
            </h3>
            <h3>
              <span role="img" aria-label="search">
                👈 {"Select Brands"}
              </span>
            </h3>
          </div>
        )}
        <div className="col-lg-11">
          <div className="container-fluid">
            <div className="row row-cols-sm-2 row-cols-md-4 row-cols-lg-6 g-1">
              {filteredProducts.map((product) => {
                return <ProductItem key={product._id} product={product} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
