import React, { useState, useContext } from "react";
import axios from "axios";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

import authContext from "../../context/authContext";
import { Spinner, Alert, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaUserEdit } from "react-icons/fa";
import { BiLogIn } from "react-icons/bi";
import UserInterface from "./UserInterface";

export default function AuthModal() {
  const { langEn, login } = useContext(authContext);
  const [showModal, setShowModal] = useState(false);

  const [isLoginMode, setIsLoginMode] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorState, setErrorState] = useState("");
  const [activating, setActivating] = useState(false);

  const [newUser, setNewUser] = useState({} as UserInterface);

  const responseGoogle = async (response: any) => {
    setIsLoading(true);
    const resDoc = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/user/login-google",
      { params: { tokenGoogle: response.credential } }
    );
    setIsLoading(false);

    if (resDoc.status === 200) {
      login(
        resDoc.data.token,
        resDoc.data._id,
        resDoc.data.fname,
        resDoc.data.role
      );
      setShowModal(false);
    } else {
      setErrorState(resDoc.statusText);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setNewUser((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleStreetInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setNewUser((prevState) => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      };
    });
  };

  type ResDocType = {
    status: number;
    data: {
      token: string;
      user_id: string;
      fname: string;
      role: string;
    };
    message?: string;
    error?: string;
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);

    try {
      let resDoc: ResDocType;
      if (!isLoginMode) {
        // sign up mode
        if (newUser.password !== newUser.password2) {
          setErrorState("Confirm password doesn't match! Try again.");
          setIsLoading(false);
          return;
        }
        delete newUser.password2;
        resDoc = await axios.post(
          process.env.REACT_APP_BACKEND_URL + "/user/signup",
          newUser
        );
        setIsLoading(false);
        if (resDoc.status === 201) {
          setActivating(true);
        } else if (resDoc.status === 401) {
          resDoc.message && setErrorState(resDoc.message);
        } else {
          resDoc.error && setErrorState(resDoc.error);
        }
      } else {
        // log in mode
        resDoc = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/login",
          { params: { email: newUser.email, password: newUser.password } }
        );
        setIsLoading(false);
        if (resDoc.status === 200) {
          login(
            resDoc.data.token,
            resDoc.data.user_id,
            resDoc.data.fname,
            resDoc.data.role
          );
          setShowModal(false);
        } else if (resDoc.status === 401) {
          resDoc.message && setErrorState(resDoc.message);
        } else if (resDoc.status === 404) {
          resDoc.message && setErrorState(resDoc.message);
        } else {
          resDoc.error && setErrorState(resDoc.error);
        }
      }
    } catch (error) {
      setIsLoading(false);
      setErrorState(error as string);
      console.error(error);
    }
  }

  return (
    <>
      <button
        className="btn btn-sm btn-outline-warning"
        onClick={() => setShowModal(true)}
      >
        <h5>
          <FaUserEdit />
          <span className="d-none d-md-inline">
            {langEn ? "SignIn" : "登录"}
          </span>
        </h5>
      </button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <ul className="nav nav-tabs card-header-tabs">
              <li className="nav-item">
                <button
                  className="btn btn-outline-warning"
                  onClick={() => setIsLoginMode(true)}
                >
                  <h5>
                    <BiLogIn />
                    {langEn ? "Log in" : "登录"}
                  </h5>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => setIsLoginMode(false)}
                >
                  <h5>
                    <FaUserEdit />
                    {langEn ? "Sign up" : "注册"}
                  </h5>
                </button>
              </li>
              <Alert
                show={!!errorState}
                variant="danger"
                onClose={() => setErrorState("")}
                dismissible
              >
                Error: {errorState}.
              </Alert>
              {isLoading && <Spinner animation="border" />}
            </ul>
          </Modal.Title>
        </Modal.Header>

        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="card-body">
              <div className="row mb-1">
                {!isLoginMode && (
                  <>
                    <div className="col-6">
                      <input
                        className="form-control"
                        required
                        type="text"
                        name="fname"
                        onChange={handleChange}
                        placeholder="👤 First name"
                      />
                    </div>
                    <div className="col-6 mb-1">
                      <input
                        className="form-control"
                        required
                        type="text"
                        name="lname"
                        onChange={handleChange}
                        placeholder="Last name 👤"
                      />
                    </div>
                    <div className="col-6 mb-1">
                      <input
                        className="form-control"
                        type="text"
                        name="phone"
                        pattern="^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$"
                        onChange={handleChange}
                        placeholder="☎︎ Phone Number"
                      />
                    </div>
                  </>
                )}

                <div className="col-6 mb-1">
                  <input
                    className="form-control"
                    required
                    type="email"
                    name="email"
                    onChange={handleChange}
                    placeholder="📧 email"
                  />
                </div>
                <div className="col-6 mb-1">
                  <input
                    className="form-control"
                    required
                    type="password"
                    name="password"
                    pattern=".{6,}"
                    placeholder="🔒Password (six+ characters)"
                    onChange={handleChange}
                  />
                </div>
                {!isLoginMode && (
                  <div className="col-6 mb-1">
                    <input
                      className="form-control"
                      required
                      type="password"
                      name="password2"
                      onChange={handleChange}
                      placeholder="🔒confirm password"
                    />
                  </div>
                )}
              </div>

              {!isLoginMode && (
                <div className="row mb-1">
                  <div className="col-12 mb-1">
                    <input
                      className="form-control"
                      type="text"
                      name="line1"
                      onChange={handleStreetInput}
                      placeholder="📫 Street address"
                    />
                  </div>
                  <div className="col-6 mb-1">
                    <input
                      className="form-control"
                      type="text"
                      name="suburb"
                      onChange={handleStreetInput}
                      placeholder="Suburb"
                    />
                  </div>
                  <div className="col-3 mb-1">
                    <input
                      className="form-control"
                      type="text"
                      name="state"
                      onChange={handleStreetInput}
                      placeholder="State"
                    />
                  </div>
                  <div className="col-3 mb-1">
                    <input
                      className="form-control"
                      type="text"
                      name="postcode"
                      onChange={handleStreetInput}
                      placeholder="Post code"
                    />
                  </div>
                </div>
              )}
            </div>

            <Alert
              show={activating}
              variant="info"
              onClose={() => setActivating(false)}
              dismissible
            >
              <h5>Account Activation Required</h5>
              <p>
                An account activation email has been sent to you. Please check
                your email and follow the instructions to activate your account.
              </p>
              <p>
                Note: if you cannot find the activation email, please check your{" "}
                <span style={{ backgroundColor: "yellow" }}>Spam Email</span> or{" "}
                <span style={{ backgroundColor: "yellow" }}>Junk Email</span>{" "}
                folder.
              </p>
            </Alert>
          </Modal.Body>

          <Modal.Footer>
            {activating ? (
              <Link
                className="btn btn-info"
                to="/"
                onClick={() => setShowModal(false)}
              >
                Return
              </Link>
            ) : (
              <div className="card-footer">
                {isLoginMode ? (
                  <div className="row">
                    <div className="col-12 text-end">
                      <button className="btn btn-warning" type="submit">
                        <h5>
                          <i className="fas fa-user-check"></i>
                          {langEn ? "Login" : "登录"}
                        </h5>
                      </button>
                    </div>
                    <div className="col-12">
                      <div
                        style={{
                          width: "100%",
                          height: "1.8rem",
                          borderBottom: "1px solid black",
                          borderTop: "1px solid black",
                          textAlign: "start",
                        }}
                      >
                        <span
                          style={{
                            // fontSize: '1rem',
                            backgroundColor: "#F3F5F6",
                            padding: "0 10px",
                          }}
                        >
                          Alternatively, sign in with Google
                        </span>
                      </div>

                      <GoogleOAuthProvider
                        clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
                      >
                        <GoogleLogin
                          // render={(renderProps: any) => {
                          //   <button
                          //     type="button"
                          //     className=""
                          //     onClick={renderProps.onClick}
                          //     disabled={renderProps.disabled}
                          //   >
                          //     sign in with google
                          //   </button>;
                          // }}
                          onSuccess={responseGoogle}
                          onError={() => {
                            console.log("login failed");
                          }}
                          // cookiePolicy="single_host_origin"
                        />
                      </GoogleOAuthProvider>
                      <div className="m-2" id="googleLoginButton"></div>
                    </div>
                  </div>
                ) : (
                  <button className="btn btn-primary" type="submit">
                    <h5>
                      <i className="fas fa-user-plus"></i>
                      {langEn ? "Register" : "注册"}
                    </h5>
                  </button>
                )}
              </div>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
