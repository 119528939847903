import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";

import authContext from "../../context/authContext";
import OrderItem from "./OrderItem";
import "./Order.css";
import { UserInfoInterface } from "../User/UserInterface";
import CartContext from "../../context/CartContext";
import OrderInterface, { OrderProductInterface } from "./OrderInterface";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";

export default function OrderCheckoutForm() {
  const { token, langEn } = useContext(authContext);
  const { cart, cartCostTotal, clearCart } = useContext(CartContext);

  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState("");
  const [confirmedOrder, setConfirmedOrder] = useState<OrderInterface>();

  const [buyer, setBuyer] = useState({} as UserInfoInterface);
  const [sameAsBilling, setSameAsBilling] = useState(true as boolean);
  const [shipping, setShipping] = useState({} as UserInfoInterface);
  // initial state cannot be null (null causes trouble in street input)
  // initial state set to {} to supress "'prevState' is possibly 'undefined'." error

  useEffect(() => {
    const fetchOwner = async () => {
      try {
        const result = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/user/getSelf",
          {
            headers: { "x-access-token": token },
          }
        );
        setBuyer(result.data);
      } catch (error) {
        console.error(error);
      }
    };
    if (token) {
      fetchOwner();
    }
  }, [token]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setShipping((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleStreetInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setShipping((prevState) => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      };
    });
  };

  const stripe = useStripe();
  const elements = useElements();

  const stripeSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setProcessingTo(true);
      if (buyer && buyer.address && stripe && elements) {
        const { fname, lname, email, phone, address } = buyer;
        const billingDetails = {
          name: fname || " " + lname,
          email: email,
          phone: phone,
          address: {
            line1: address.line1,
            city: address.suburb,
            state: address.state,
            postal_code: address.postcode,
            country: "AU",
          },
        };

        let delAddr = {} as UserInfoInterface;
        if (sameAsBilling) {
          delAddr = {
            fname,
            lname,
            email,
            phone,
            address,
          };
        } else if (Object.keys(shipping).length > 0) {
          delAddr = shipping;
        } else {
        }

        const cardElement = elements.getElement("card");

        let _orderProdArr = [] as OrderProductInterface[];
        cart.forEach((cartItem) => {
          const { product_id, name, price, includeGST, unit, quantity } =
            cartItem;
          const _orderProd: OrderProductInterface = {
            product_id,
            name,
            price,
            GST: includeGST ? price / 11 : 0,
            unit,
            quantity,
          };
          _orderProdArr.push(_orderProd);
        });

        const { data: client_secret } = await axios.post(
          process.env.REACT_APP_BACKEND_URL + "/order/stripepay",
          _orderProdArr,
          {
            headers: { "x-access-token": token },
          }
        );

        const paymentMethodReq = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement!,
          billing_details: billingDetails,
        });

        if (paymentMethodReq.error) {
          setCheckoutError(
            paymentMethodReq.error.message || "Payment method error"
          );
          setProcessingTo(false);
          return;
        }

        const paymentConfirm = await stripe.confirmCardPayment(client_secret, {
          payment_method: paymentMethodReq.paymentMethod.id, // payment_method is payment_id
        });

        if (paymentConfirm.error) {
          setCheckoutError(
            paymentConfirm.error.message || "Payment confirm error"
          );
          setProcessingTo(false);
          return;
        }

        // console.log('SUCCESS!', paymentConfirm.paymentIntent.payment_method);
        const { data: confirmedOrder } = await axios.post(
          process.env.REACT_APP_BACKEND_URL + "/order",
          { cart, paymentMethodReq, paymentConfirm, shipping: delAddr },
          {
            headers: { "x-access-token": token },
          }
        );
        setConfirmedOrder(confirmedOrder);
        clearCart();
      }
      setProcessingTo(false);
    } catch (error) {
      setCheckoutError(error.message);
    }
  };

  const handleCardDetailsChange = (ev: StripeCardElementChangeEvent) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError("");
  };

  const iframeStyles = {
    base: {
      color: "#fff",
      // backgroundColor: '#6772e5',
      fontSize: "16px",
      iconColor: "#fff",
      "::placeholder": {
        color: "#87bbfd",
      },
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#FFC7EE",
    },
    complete: {
      iconColor: "#cbf4c9",
    },
  };

  const cardElementOpts = {
    iconStyle: "solid" as any,
    style: iframeStyles,
    padding: "15px",
    hidePostalCode: true,
  };

  return (
    <div>
      {!confirmedOrder && buyer && shipping && (
        <>
          {/* <OrderItem
              title={<h5>{langEn ? 'Cart Summary' : '购物车内容：'}</h5>}
              order={cartOrder}
            /> */}

          <div className="card card-stripe m-2">
            <div className="card-header" style={{ color: "#fff" }}>
              <h5>Payment Details:</h5>
            </div>

            <div className="card-stripe-element">
              <div className="row">
                <div className="col-6">
                  <h5>Billing details:</h5>
                </div>
                <div className="col-6">
                  <a href="/user/update" className="text-white">
                    Update Profile
                  </a>
                </div>
                <div className="col-3">Name:</div>
                <div className="col-9">{buyer.fname + " " + buyer.lname}</div>
              </div>
              <div className="row">
                <div className="col-3">Email:</div>
                <div className="col-9">{buyer.email}</div>
              </div>
              <div className="row">
                <div className="col-3">Phone:</div>
                <div className="col-9">{buyer.phone}</div>
              </div>
              <div className="row">
                <div className="col-3">Address:</div>
                {buyer.address && (
                  <div className="col-9">
                    {buyer.address.line1}
                    <br />
                    {buyer.address.suburb}, {buyer.address.state}{" "}
                    {buyer.address.postcode}
                  </div>
                )}
              </div>
            </div>

            <form onSubmit={stripeSubmit}>
              <div className="card-stripe-element">
                <div className="row">
                  <h5 className="col" style={{ display: "inline-block" }}>
                    Deliver to:
                  </h5>
                  <div
                    className="col form-check"
                    style={{ display: "inline-block" }}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="deliveryAddressCheck"
                      checked={sameAsBilling}
                      onChange={(event) => {
                        setSameAsBilling(event.target.checked);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="deliveryAddressCheck"
                    >
                      same as Billing
                    </label>
                  </div>
                </div>
                {!sameAsBilling && (
                  <div className="row g-1 mb-1">
                    <div className="col-2">Name:</div>
                    <div className="col-5">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="fname"
                        required
                        onChange={handleChange}
                        placeholder="👤 first name"
                      />
                    </div>
                    <div className="col-5">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="lname"
                        required
                        onChange={handleChange}
                        placeholder="last name 👤"
                      />
                    </div>
                    <div className="col-2">Email:</div>
                    <div className="col-10">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="email"
                        required
                        onChange={handleChange}
                        placeholder="📧 email@domain"
                      />
                    </div>
                    <div className="col-2">Phone:</div>
                    <div className="col-10">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="phone"
                        required
                        pattern="^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$"
                        onChange={handleChange}
                        placeholder="☎ Mobile Number"
                      />
                    </div>
                    <div className="col-2">
                      <small>Address:</small>
                    </div>
                    <div className="col-10">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="line1"
                        required
                        onChange={handleStreetInput}
                        placeholder="📫 Street address"
                      />
                    </div>
                    <div className="col-2"></div>
                    <div className="col-5">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="suburb"
                        required
                        onChange={handleStreetInput}
                        placeholder="suburb"
                      />
                    </div>
                    <div className="col-3">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="state"
                        required
                        onChange={handleStreetInput}
                        placeholder="state"
                      />
                    </div>
                    <div className="col-2">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        name="postcode"
                        required
                        onChange={handleStreetInput}
                        placeholder="postcode"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="card-stripe-element">
                <h5>Card details:</h5>
                <CardElement
                  options={cardElementOpts}
                  onChange={handleCardDetailsChange}
                />
                <div className="my-2">
                  <a
                    href="https://stripe.com/docs/currencies"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Users in Australia can accept Visa Mastercard American Express JCB credit and debit cards."
                  >
                    <img
                      src={"/img/cc_visa.png"}
                      alt="visa"
                      style={{ height: "20px" }}
                    />{" "}
                    <img
                      src={"/img/cc_master.png"}
                      alt="master"
                      style={{ height: "20px" }}
                    />{" "}
                    <img
                      src={"/img/cc_american.png"}
                      alt="american"
                      style={{ height: "20px" }}
                    />{" "}
                    <img
                      src={"/img/cc_jcb.png"}
                      alt="jcb"
                      style={{ height: "20px" }}
                    />
                    <span style={{ color: "#87bbfd" }}>
                      {" "}
                      Accept major cards
                    </span>
                  </a>
                </div>
              </div>

              <div className="card-stripe-element">
                <div className="my-1">
                  {checkoutError && <h3>{checkoutError}</h3>}
                  <div className="my-1 text-center">
                    <button
                      className="btn btn-light btn-stripe"
                      type="submit"
                      disabled={isProcessing || !stripe}
                      title="Your payment is secured by stripe.com"
                    >
                      <i className="fas fa-lock"></i>
                      {isProcessing
                        ? " Processing... "
                        : ` *Pay: $${cartCostTotal.toFixed(2)} `}
                    </button>
                    <br />
                    *powered by{" "}
                    <b>
                      <a
                        href="https://stripe.com/au"
                        className="text-white"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Stripe Payment
                      </a>
                    </b>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <small>
            *The credit card information and payment are processed by
            <br />
            <a href="https://stripe.com/au" target="_blank" rel="noreferrer">
              Stripe Payment
            </a>
            , we do not keep your credit card details.
          </small>
          {checkoutError && <div>{checkoutError}</div>}
        </>
      )}

      {confirmedOrder && buyer && (
        <>
          <h1>Payment Confirmation</h1>
          <OrderItem orderItem={confirmedOrder} />

          <Link to="/">
            <button className="btn btn-outline-light btn-primary m-2">
              <i className="fas fa-store"></i>
              {langEn ? "Back to Shop" : "回到商店"}
            </button>
          </Link>
        </>
      )}
    </div>
  );
}
