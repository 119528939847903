import React from "react";

import MapGeocoder from "../Map/MapGeocoder";
import {
  E_NoseInterface,
  FreshnessInterface,
  ImageProcInterface,
  IoTDataInterface,
} from "./SFMBoxInterface";

type props = {
  freshness: FreshnessInterface[];
  iotResult: IoTDataInterface;
  imageProc: ImageProcInterface;
  e_nose: E_NoseInterface;
};
export default function SFMBoxDetailsFresh({
  freshness,
  iotResult,
  imageProc,
  e_nose,
}: props) {
  const starRating = [
    "",
    "⭐️",
    "⭐️⭐️",
    "⭐️⭐️⭐️",
    "⭐️⭐️⭐️⭐️",
    "⭐️⭐️⭐️⭐️⭐️",
    "⭐️⭐️⭐️⭐️⭐️",
  ];

  return (
    <div>
      <h5>Freshness Assessment</h5>
      {freshness.length > 0 && (
        <>
          {freshness[freshness.length - 1].freshnessIndex < 1.0 && (
            <div>
              Rating:{" "}
              {
                starRating[
                  Math.floor(
                    6 - 2 * freshness[freshness.length - 1].freshnessIndex
                  )
                ]
              }
            </div>
          )}
          <div>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <th>Freshness Score</th>
                  <th>IoT</th>
                  <th>e-eye</th>
                  <th>e-nose</th>
                </tr>
                <tr>
                  <td>
                    {freshness[freshness.length - 1].freshnessIndex.toFixed(2)}
                  </td>
                  <td>{iotResult.iotScore.toFixed(2)}</td>
                  <td>{imageProc.imgScore.toFixed(2)}</td>
                  <td>{e_nose.noseScore.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            Date, Time:{" "}
            {freshness[freshness.length - 1].time.split(" ")[0].substr(0, 15)},{" "}
            {freshness[freshness.length - 1].time.split(" ")[1].substr(0, 5)}
          </div>
          <div>
            Address:{" "}
            <MapGeocoder lat={iotResult.latitude} lng={iotResult.longitude} />
          </div>
        </>
      )}
    </div>
  );
}
