import React from "react";
import { FisherInterface } from "./SFMBoxInterface";

type props = {
  fisher: FisherInterface;
};

export default function SFMBoxDetailsFisher({ fisher }: props) {
  return (
    <div>
      <h5>Fisher:</h5>
      <div>ID: {fisher.traderID}</div>
      <div>Name: {fisher.traderName}</div>
      <div>Company: {fisher.companyName}</div>
      <div>Boat: {fisher.boat}</div>
      <div>Address: {fisher.address}</div>
    </div>
  );
}
