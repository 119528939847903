import React from "react";

export default function ProductManageColumns() {
  return (
    <div className="row">
      <div className="col-1">name</div>
      <div className="col-1">名称</div>
      <div className="col-1">brand</div>
      <div className="col-1">Category</div>
      <div className="col-1">image</div>
      <div className="col-1 text-end">price</div>
      <div className="col-1">unit</div>
      <div className="col-1 text-center">GST</div>
      <div className="col-1 text-end">stock</div>
      <div className="col-1 text-center">onShelf</div>
      <div className="col-1">aisle.shelf.level</div>
      <div className="col-1">save/edit</div>
      <div className="col-1"></div>
    </div>
  );
}
